import React, { useEffect, useState } from 'react';
import './styles.scss';
import { ESignInSteps } from '@pages/AuthPage/config/enums';
import '@pages/AuthPage/ui/PhoneStep/style.scss';

import Logo from '@shared/assets/Images/logo_yellow.svg';

import 'react-international-phone/style.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { localStorageGetItem } from '@shared/lib/utils/localStorage';

import { STORAGE_KEYS } from '@pages/AuthPage/config/constants';

import { PhoneStep } from './ui/PhoneStep';
import { OTPStep } from './ui/OTPStep';

const AuthPage: React.FC = () => {
  const { t } = useTranslation();
  const [signInStep, setSignInStep] = useState<ESignInSteps>(ESignInSteps.PhoneStep);
  const [phone, setPhone] = useState<string>('');

  const navigate = useNavigate();
  const isAuth = !!localStorageGetItem(STORAGE_KEYS.TOKEN);

  useEffect(() => {
    if (isAuth) {
      navigate(-1);
    }
  }, [isAuth, navigate]);

  return (
    <div className="auth">
      <div className="auth__description">
        <div className="auth__info">
          <div className="auth__info__logo">
            <img src={Logo}
              alt="@YourService"
              width="auto"
              height="64" />
          </div>
          <h1>{t('login.page-title')}</h1>
        </div>
      </div>
      <div className="auth__form">
        <div className="auth__form__inner">
          {signInStep === 'phone'
            ? <PhoneStep phone={phone} setPhone={setPhone} setSignInStep={setSignInStep} />
            : <OTPStep phone={phone} setSignInStep={setSignInStep} />}
        </div>

        <footer className="auth__footer">
          <div className="auth__footer__copy vcard"><span className="org">{t('login.ys')}&nbsp;</span>{t('login.rights')}&nbsp;&copy;&nbsp;2024
          </div>
          <div className="auth__footer__terms"><a href="https://docs.google.com/document/d/1wDVOikB9kaWAjANqHR8bizf52Nmk-TiXTdAKRfgEGyY/edit?usp=sharing" target="_blank" rel="nofollow noreferrer">{t('login.terms')}</a></div>
          <div className="auth__footer__privacy"><a href="https://docs.google.com/document/d/1CpZbUjsIAW_hTlyqD6kDoKSFcJlh4iY36ZN5CXJqA-U/edit?usp=sharing" target="_blank" rel="nofollow noreferrer">{t('login.privacy')}</a></div>
        </footer>

      </div>
    </div>
  );
};

export default AuthPage;
