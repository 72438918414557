import { type MutateFunction, useMutation, type UseMutationResult } from '@tanstack/react-query';
import { type AxiosPromise, type AxiosResponse } from 'axios';
import { apiClientAWS } from '@shared/api/base';

import { type IVideoDimensions } from '@widgets/CreateServiceRequestWidget/ui/UploadVideo';

import { type IImageDimensions } from '@widgets/CreateServiceRequestWidget/ui/UploadPhoto';

import { MEDIA_TYPES } from '../config/constants';

interface IUploadMediaProcess {
  'Content-Type': string
  'key': string
  'policy': string
  'x-amz-algorithm': string
  'x-amz-credential': string
  'x-amz-date': string
  'x-amz-signature': string
  file: File
  url: string
}

export const useUploadMediaProcess = ({
  type,
  serviceRequestId,
  dimensionsObj,
  file,
  finishFunction,
  key,
  coverKey,
}: { serviceRequestId: string
  file: File | null
  dimensionsObj: IVideoDimensions | IImageDimensions | null
  finishFunction: (...args: Parameters<MutateFunction<unknown, unknown, unknown, unknown>>) => void
  type: MEDIA_TYPES
  key: string
  coverKey?: string
}): UseMutationResult<
  unknown,
  unknown,
  IUploadMediaProcess,
  unknown
  > => {
  const uploadMediaProcess = async (payload: IUploadMediaProcess): AxiosPromise<AxiosResponse> => {
    return await apiClientAWS.post(
      payload.url,
      payload,
    );
  };

  const mutationOptions: {
    mutationFn: (payload: IUploadMediaProcess) => AxiosPromise<AxiosResponse>
    onSuccess: (data: AxiosResponse) => void
  } = {
    mutationFn: uploadMediaProcess,
    onSuccess: () => {
      if (type === MEDIA_TYPES.VIDEO) {
        const videoRequest = {
          key,
          file_size: file?.size,
          width: dimensionsObj?.width,
          height: dimensionsObj?.height,
          duration: (dimensionsObj as IVideoDimensions).duration,
          codec: (dimensionsObj as IVideoDimensions).codec,
          cover: {
            key: coverKey,
            file_size: (dimensionsObj as IVideoDimensions).cover.size,
            width: (dimensionsObj as IVideoDimensions).cover.width,
            height: (dimensionsObj as IVideoDimensions).cover.height,
          },
          service_request_id: serviceRequestId,
        };
        finishFunction(videoRequest);
      }
      if (type === MEDIA_TYPES.IMAGE) {
        const photoRequest = {
          key,
          file_size: file?.size,
          width: dimensionsObj?.width,
          height: dimensionsObj?.height,
          service_request_id: serviceRequestId,
        };
        finishFunction(photoRequest);
      }
    },
  };

  return useMutation(mutationOptions);
};
