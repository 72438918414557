import React, { type ReactNode } from 'react';
import { ThemeProvider } from '@mui/material';
import { Theme } from '@shared/theme';
import CssBaseline from '@mui/material/CssBaseline';

interface MuiProviderProps {
  children: ReactNode
}

export const MuiProvider: React.FC<MuiProviderProps> = ({ children }) => {
  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
