// @ts-nocheck
import axios from 'axios';
import { STORAGE_KEYS } from '@pages/AuthPage/config/constants';
import { localStorageGetItem, localStorageRemoveItem } from '@shared/lib/utils/localStorage';
import { ROUTES } from '@shared/config/routes/constants';

const axiosConfig = {
  baseURL: import.meta.env.APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
};

const axiosConfigAWS = {
  baseURL: import.meta.env.APP_API_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

export const apiClient = axios.create(axiosConfig);
export const apiClientAWS = axios.create(axiosConfigAWS);

apiClient.interceptors.request.use((config) => {
  config.headers = { ...config.headers };
  const token = localStorageGetItem(STORAGE_KEYS.TOKEN);
  if (token) {
    config.headers.Authorization = `Token ${token}`;
  }
  return config;
});

apiClient.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && error.config && !error.config._isRetry) {
      originalRequest._isRetry = true;
      localStorageRemoveItem(STORAGE_KEYS.TOKEN);
      window.location = ROUTES.AUTH;
    }
  },
);
