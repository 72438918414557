// @ts-nocheck
import React, { useEffect, useState } from 'react';
import './styles.scss';
import { Drawer } from '@mui/material';
import IconClose from '@shared/assets/Icons/icon_close.svg';
import Invoice from '@widgets/MakeAppointmentWidget/ui/Invoice';
import { type IProduct } from '@widgets/MakeAppointmentWidget/config/interfaces';
import { useSendInvoice } from '@widgets/CreateInvoiceWidget/model/useSendInvoice';
import AddInvoice from '@widgets/MakeAppointmentWidget/ui/AddInvoice';
import IconPlus from '@shared/assets/Icons/icon_plus_accent.svg';
import { useFetchProducts } from '@widgets/MakeAppointmentWidget/model/useFetchProducts';
import { useParams } from 'react-router-dom';
import IconEdit from '@shared/assets/Icons/icon_edit.svg';
import { useGetInvoice } from '@widgets/CreateInvoiceWidget/model/useGetInvoice';
import { useServiceRequest } from '@widgets/DetailServiceRequestWidget/model/useServiceRequest';
import { ModalWindow } from '@widgets/CreateServiceRequestWidget/ui';
import { useTouchServiceRequest } from '@widgets/DetailServiceRequestWidget/model/useTouch';
import { useTranslation } from 'react-i18next';
import { TIME_TYPES } from '@shared/config/common/constants';

interface IInvoiceWidgetProps {
  isVisible: boolean
  setVisible: (boolean) => void
  products?: IProduct[]
  data?: unknown
}

const CreateInvoiceWidget: React.FC<IInvoiceWidgetProps> = ({ isVisible, setVisible, data }) => {
  const { t } = useTranslation();
  const [currentProduct, setCurrentProduct] = useState<IProduct>(null);
  const [currentTodo, setCurrentTodo] = useState(null);
  const [isInvoiceVisible, setInvoiceVisible] = useState<boolean>(false);
  const [isSuccessSendVisible, setSuccessSendVisible] = useState<boolean>(false);

  const onSuccessSendInvoice = () => {
    setVisible(false);
    setSuccessSendVisible(true);
  };

  const { id } = useParams();
  const { data: serviceRequest } = useServiceRequest(id as string);
  const { data: invoice, refetch: refetchInvoice } = useGetInvoice(serviceRequest?.invoice_id as string);
  const { mutateAsync: sendInvoice } = useSendInvoice(id, onSuccessSendInvoice, serviceRequest?.invoice_id);
  const { mutate: touchServiceRequest } = useTouchServiceRequest(id);
  const { data: products, refetch: refetchProducts } = useFetchProducts(id as string);


  useEffect(() => {
    refetchProducts();
  }, [refetchInvoice, refetchProducts, serviceRequest]);

  const handleSendInvoice = async (): Promise<void> => {
    if (data?.id) {
      await sendInvoice(data?.id);
      setVisible(false);
      touchServiceRequest();
    }
  };

  return (
    <>
      <Drawer
        open={isVisible}
        onClose={() => {
          setVisible(false);
        }}
        anchor="right"
      >
        <button
          className="button button--small button--drawer"
          style={{ position: 'absolute', zIndex: 15 }}
          onClick={() => {
            setVisible(false);
          }}>
          <img src={IconClose} width="32" height="32" alt="close"/>
        </button>
        <div className="preliminary-invoice_container">
          <h2 style={{ marginBottom: '24px' }}>{t('invoice.request-payment')}</h2>
          {invoice?.records?.length > 0 && (
            <div className="preliminary-invoice">
              <h4 style={{ marginBottom: '8px' }}>{t('invoice.service-summary')}</h4>
              <div className="product-items">
                <div className="products_header">
                  <div className="products_header-service">{t('invoice.service-item')}</div>
                  <div className="products_header-qty">{t('invoice.qty')}</div>
                  <div className="products_header-price">{t('invoice.price')}</div>
                  <div className="products_header-empty" />
                </div>
                {invoice?.records.map((record) => <div key={record.id} className="product-item">
                  <div className="product-item_service">{t(TIME_TYPES[record.service_type])}</div>
                  <div className="products-item_-qty">{record.duration}</div>
                  <div className="products-item_price">
                    <div className="products-item_price-main">{record.price}€</div>
                    {/*<div className="products-item_price-detail">{record.price}€/{record.price}</div>*/}
                  </div>
                  <div className="products-item_edit-invoice">
                    <button type="button" className="button button--small product-item_edit-btn">
                      <img src={IconEdit} onClick={() => { setCurrentTodo(record); setInvoiceVisible(true); }} width="32" height="32" alt="edit"/>
                    </button>
                  </div>
                </div>)}
              </div>
            </div>
          )}
          {products?.length > 0 && (
            <div className="preliminary-invoice" style={{ marginTop: '24px', marginBottom: '56px' }}>
              <h4 style={{ marginBottom: '8px' }}>{t('invoice.service-items')}</h4>
              <div className="product-items">
                <div className="products_header">
                  <div className="products_header-service">{t('invoice.service-item')}</div>
                  <div className="products_header-qty">{t('invoice.qty')}</div>
                  <div className="products_header-price">{t('invoice.price')}</div>
                  <div className="products_header-empty" />
                </div>
                {products.map((product, index) => <Invoice
                  setVisibleForm={setInvoiceVisible}
                  product={product}
                  setProduct={setCurrentProduct}
                  key={index} />)}
              </div>
            </div>
          )}
          <div className="form__row">
            <button
              className="button button--text-small"
              style={{ paddingLeft: 0 }}
              type="button"
              onClick={() => { setCurrentProduct(null); setInvoiceVisible(true); }}
            >
              <img src={IconPlus} width="20" height="20" alt="Add a time period"/>
              {t('invoice.add-invoice-item')}
            </button>
          </div>
          <div className="product-items_total" style={{ marginBottom: '24px' }}>
            <div className="product-items_total-subtotal">
              <span>{t('invoice.subtotal')}</span>
              <span>{invoice?.subtotal}€</span>
            </div>
            <div className="product-items_total-vat">
              <span>{t('invoice.vat')} {invoice?.vat_rate ? invoice?.vat_rate : 0}%</span>
              <span>{invoice?.vat_amount ? invoice?.vat_amount : 0}€</span>
            </div>
            <div className="product-items_total-totalsum">
              <span>{t('invoice.total-sum')}</span>
              <span>{invoice?.total}€</span>
            </div>
          </div>
          <div className="buttons__group" style={{ padding: '32px 0' }}>
            <button
              type="button"
              className="button button--border"
              onClick={() => { setVisible(false); }}
            >
              {t('invoice.cancel')}
            </button>
            <button
              className="button button--default"
              type="submit"
              onClick={handleSendInvoice}
            >
              {t('invoice.send-request-payment')}
            </button>
          </div>
        </div>
        <AddInvoice
          isVisible={isInvoiceVisible}
          setVisible={setInvoiceVisible}
          product={currentProduct}
          todo={currentTodo}
          setTodo={setCurrentTodo}
          setCurrentProduct={setCurrentProduct}
        />
      </Drawer>
      <ModalWindow
        isVisible={isSuccessSendVisible}
        setIsVisible={setSuccessSendVisible}
        title={t('modals.payment-title')}
        subtitle={t('modals.payment-subtitle')}
        description={t('modals.payment-description')}
        secondBtnText={t('modals.payment-yes')}
        secondBtnAction={() => {
          setSuccessSendVisible(false);
        }}
      />
    </>
  );
};

export default CreateInvoiceWidget;
