import React from 'react';
import { BrowserRouter } from '@app/providers/BrowserRouter';
import { MuiProvider } from '@app/providers/MuiProvider';
import { QueryClientProvider } from '@app/providers/QueryClientProvider';

const Providers: React.FC = () => {
  return (
    <MuiProvider>
      <QueryClientProvider>
        <BrowserRouter />
      </QueryClientProvider>
    </MuiProvider>
  );
};

export default Providers;
