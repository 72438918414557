import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Logout from '@shared/assets/Icons/icon-logout.svg';
import { Button } from '@mui/material';
import { useLogOut } from '@features/LogOut/model/useLogOut';
import ModalWindow from '@widgets/CreateServiceRequestWidget/ui/ModalWindow';

const LogOut: React.FC = () => {
  const { t } = useTranslation();
  const logOut: () => void = useLogOut();
  const [isVisibleLogoutModal, setVisibleLogoutModal] = useState(false);

  return (
    <>
      <Button
        variant="text"
        disableRipple
        sx={{
          font: '600 1.4rem/1.6 var(--font-main)',
          padding: '0 8px',
          margin: '0',
          boxShadow: 'none !important',
          backgroundColor: 'transparent',
          height: '32px',
          color: 'var(--accent)',
          display: 'flex',
          alignItems: 'center',
          transition: 'all 0.25s linear',
          '&:hover': {
            color: 'var(--accent-hover)',
            backgroundColor: 'transparent',
          },
          '&:active': {
            color: 'var(--accent-active)',
            backgroundColor: 'transparent',
          },
        }}
        onClick={() => { setVisibleLogoutModal(true); }}
      >
        <img src={Logout} alt="Logout" style={{ marginRight: '4px' }}/>
        {t('login.logout')}
      </Button>
      <ModalWindow
        isVisible={isVisibleLogoutModal}
        setIsVisible={setVisibleLogoutModal}
        title={t('modals.logout-title')}
        subtitle={t('modals.logout-subtitle')}
        description={t('modals.logout-description')}
        firstBtnText={t('modals.logout-no')}
        secondBtnText={t('modals.logout-yes')}
        firstBtnAction={() => { setVisibleLogoutModal(false); }}
        secondBtnAction={logOut}
      />
    </>
  );
};

export default LogOut;
