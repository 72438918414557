import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { apiClient } from '@shared/api/base';
import { type AxiosPromise, type AxiosResponse } from 'axios';
import { type ITechniciansList } from '@features/TechniciansListTable/config/interfaces';
import { localStorageGetItem } from '@shared/lib/utils/localStorage';
import { STORAGE_KEYS } from '@pages/AuthPage/config/constants';

export const useTechniciansList = (page: number, pageSize: number): UseQueryResult<ITechniciansList, unknown> => {
  const getTechnicians = async (page = 1, pageSize = 20): AxiosPromise<AxiosResponse> => {
    const response = await apiClient.get(`provider/technicians/?page=${page}&page_size=${pageSize}`, {
      withCredentials: true,
      headers: {
        Authorization: `Token ${localStorageGetItem(STORAGE_KEYS.TOKEN)}`,
      },
    });
    return response.data;
  };

  return useQuery(
    {
      queryKey: ['team', page, pageSize],
      queryFn: async () => await getTechnicians(page, pageSize),
    });
};
