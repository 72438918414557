import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { apiClient } from '@shared/api/base';
import { type AxiosPromise, type AxiosResponse } from 'axios';
import { type IServiceRequest } from '@widgets/DetailServiceRequestWidget/config/interfaces';

export const useServiceRequest = (id: string):
    UseQueryResult<IServiceRequest, unknown> => {
  const getServiceRequest = async (id: string): AxiosPromise<IServiceRequest> => {
    const response = await apiClient.get(`provider/service-requests/${id}/`, {
      withCredentials: true,
    });
    return response.data;
  };

  const queryOptions = {
    queryKey: ['service-request', id],
    queryFn: async () => await getServiceRequest(id),
    onSuccess: (data: AxiosResponse<IServiceRequest>) => {
      // eslint-disable-next-line no-console
      console.log(data);
    },
    onError: (error: unknown) => {
      // eslint-disable-next-line no-console
      console.error(error);
    },
  };

  return useQuery(queryOptions);
};
