// @ts-nocheck
import { useMutation, type UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { apiClient } from '@shared/api/base';
import { type AxiosPromise, type AxiosResponse } from 'axios';
import { type IInvoiceRequest } from '@widgets/CreateInvoiceWidget/config/interfaces';

export const useUpdateInvoice = (id: string, onSuccess): UseMutationResult<unknown, unknown, IInvoiceRequest, unknown> => {
  const updateInvoice = async (newValues: IInvoiceRequest): AxiosPromise<AxiosResponse> => {
    return await apiClient.put(
      `provider/invoices/${id}/`,
      newValues,
    );
  };
  const queryClient = useQueryClient();
  const mutationOptions: {
    onError: (error: unknown) => void
    mutationFn: (payload: IInvoiceRequest) => AxiosPromise<AxiosResponse>
    onSuccess: (data: unknown) => void
  } = {
    mutationFn: async (newValues) => await updateInvoice(newValues),
    onSuccess: (data) => {
      queryClient.setQueryData(['get-invoice', id], () => ({
        ...data?.data,
      }));
      onSuccess();
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
    },
  };

  return useMutation(mutationOptions);
};
