import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { AuthLayout, MainLayout } from '@app/layouts';
import { DashboardPage, AuthPage, ClientsPage, TechniciansPage, NotFoundPage } from '@pages/index';

import { ROUTES } from '@shared/config/routes/constants';
import DetailServiceRequestWidget from '@widgets/DetailServiceRequestWidget';

const router = createBrowserRouter([
  {
    element: <MainLayout />,
    children: [
      {
        path: ROUTES.MAIN,
        element: <DashboardPage />,
      },
      {
        path: ROUTES.CLIENTS,
        element: <ClientsPage />,
      },
      {
        path: ROUTES.TEAM,
        element: <TechniciansPage />,
      },
      {
        path: ROUTES.DETAIL_SERVICE_REQUEST,
        element: <DetailServiceRequestWidget />,
      },
      {
        path: ROUTES.NOT_FOUND,
        element: <NotFoundPage />,
      },
    ],
  },
  {
    element: <AuthLayout />,
    children: [
      {
        path: ROUTES.AUTH,
        element: <AuthPage />,
      },
      {
        path: ROUTES.NOT_FOUND,
        element: <NotFoundPage />,
      },
    ],
  },
]);

export const BrowserRouter: React.FC = () => {
  return <RouterProvider router={router} />;
};
