// @ts-nocheck
import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { apiClient } from '@shared/api/base';
import { queryClient } from '@shared/api/query-client';
import { type AxiosPromise, type AxiosResponse } from 'axios';
import { type IProduct } from '@widgets/MakeAppointmentWidget/config/interfaces';

export const useDeleteProduct = (productId, id): UseMutationResult<unknown, unknown, IProduct, unknown> => {
  const deleteProduct = async (): AxiosPromise<AxiosResponse> => {
    return await apiClient.delete(
      `provider/products/${productId}/`,
    );
  };

  const mutationOptions: {
    onError: (error: unknown) => void
    mutationFn: (payload: IProduct) => AxiosPromise<AxiosResponse>
    onSuccess: (data: unknown) => void
  } = {
    mutationFn: deleteProduct,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['products', id] });
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
    },
  };

  return useMutation(mutationOptions);
};
