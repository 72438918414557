export const TECHNICIAN_SCHEDULE = [
  {
    weekday: 1,
    weekday_title: 'technicianProfile.monday',
  },
  {
    weekday: 2,
    weekday_title: 'technicianProfile.tuesday',
  },
  {
    weekday: 3,
    weekday_title: 'technicianProfile.wednesday',
  },
  {
    weekday: 4,
    weekday_title: 'technicianProfile.thursday',
  },
  {
    weekday: 5,
    weekday_title: 'technicianProfile.friday',
  },
  {
    weekday: 6,
    weekday_title: 'technicianProfile.saturday',
  },
  {
    weekday: 7,
    weekday_title: 'technicianProfile.sunday',
  },
] as Array<{ weekday: number, weekday_title: string }>;

