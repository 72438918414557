// @ts-nocheck
import { Button, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ESignInSteps } from '@pages/AuthPage/config/enums';
import { useSignIn } from '@pages/AuthPage/model/useSignIn';
import { useSignInConfirm } from '@pages/AuthPage/model/useSignInConfirm';

interface OTPStepProps {
  setSignInStep: React.Dispatch<React.SetStateAction<ESignInSteps>>
  phone: string
}

export const OTPStep: React.FC<OTPStepProps> = (props: OTPStepProps) => {
  const { t } = useTranslation();
  const { phone, setSignInStep } = props;
  const [errorCodeInput, setErrorCodeInput] = useState('');
  const { mutate: signInConfirm, error: confirmError, isError: isConfirmError } = useSignInConfirm();
  const { mutate: signIn, error: resendError, isError: isResendError } = useSignIn();

  const [sms, setSMS] = useState<string>('');
  const [seconds, setSeconds] = useState<number>(59);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return (): void => {
      clearInterval(interval);
    };
  }, [seconds]);

  // TODO: Избавиться после определения формата ошибок на бэке
  useEffect(() => {
    if (confirmError) {
      if (Object.prototype.hasOwnProperty.call(confirmError.response?.data?.errors, 'non_field_errors')) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setErrorCodeInput(confirmError.response?.data?.errors?.non_field_errors[0]);
      }
      if (Object.prototype.hasOwnProperty.call(confirmError.response?.data?.errors, 'detail')) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setErrorCodeInput(confirmError.response?.data?.errors?.detail);
      }
    }
    if (resendError) {
      if (Object.prototype.hasOwnProperty.call(resendError.response?.data?.errors, 'non_field_errors')) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setErrorCodeInput(resendError.response?.data?.errors?.non_field_errors[0]);
      }
      if (Object.prototype.hasOwnProperty.call(resendError.response?.data?.errors, 'detail')) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setErrorCodeInput(resendError.response?.data?.errors?.detail);
      }
    }
  }, [confirmError, resendError]);

  const onChangeSmsInput = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    if (event.target.value.length === 6) {
      const code = event.target.value;
      signInConfirm({ phone, code });
    }
    setSMS(event.target.value);
  };

  return (
    <form>
      <h2>{t('login.sms-header')}</h2>
      <div className="form__message">
        {t('login.sms-send-label', { phone })}
        <Button disableRipple
          variant={'text'}
          onClick={() => { setSignInStep(ESignInSteps.PhoneStep); }}
          sx={{
            fontSize: '14px',
            padding: 0,
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}>{t('login.phone-change-button')}</Button>
      </div>
      <div className="form__label">{t('login.sms-label')}<span className="required">*</span></div>
      <TextField style={{ width: '100%', marginBottom: '8px', height: '48px' }}
        InputProps={{
          style: { borderRadius: '8px', fontSize: '14px' },
        }}
        placeholder={'000000'}
        value={sms}
        onChange={(event) => {
          onChangeSmsInput(event);
        }} />
      {(isConfirmError || isResendError) && errorCodeInput.length !== 0 &&
        <div className="error">{errorCodeInput}</div>
      }
      <br/>
      {seconds > 0
        ? (
          <Typography
            variant={'caption'}
            color={'info.main'}
            fontSize={'14px'}
          >
            {t('login.sms-resend-label', { second: seconds < 10 ? `0${seconds}` : seconds })}
          </Typography>)
        : (
          <Button
            sx={{
              fontSize: '14px',
              padding: 0,
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
            disableRipple
            variant={'text'}
            onClick={() => { signIn({ phone }); } }
          >
            {t('login.sms-resend-button')}
          </Button>)}
    </form>
  );
};
