export const TIME_TYPES = {
  COMMUTING_TIME: 'invoice.commuting-time',
  PRE_SERVICE_ANALYSIS: 'invoice.pre-service-analysis',
  SERVICE: 'invoice.service-in-progress',
  POST_SERVICE_REPORT: 'invoice.post-service',
  TOTAL_TIME: 'invoice.total-time',
} as Record<string, string>;

export const TODO_TYPES = {
  PRE_SERVICE_ANALYSIS: 'todoTypes.pre-service',
  SERVICE: 'todoTypes.in-progress',
  POST_SERVICE_REPORT: 'todoTypes.post-service',
} as Record<string, string>;
