import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { apiClient } from '@shared/api/base';
import { type AxiosPromise } from 'axios';
import { localStorageGetItem } from '@shared/lib/utils/localStorage';
import { STORAGE_KEYS } from '@pages/AuthPage/config/constants';
import { type IServiceRequestsList } from '@pages/DashboardPage/config/interfaces';

export const useServiceRequestsList = (statusFilter: string, typeWorkFilter: string):
    UseQueryResult<IServiceRequestsList[], unknown> => {
  const getServiceRequestsList = async (
    statusFilter = '',
    typeWorkFilter = '',
  ): AxiosPromise<IServiceRequestsList[]> => {
    const response =
      await
      apiClient.get(`provider/service-requests/?option=dashboard&status=${statusFilter}&category=${typeWorkFilter}`, {
        withCredentials: true,
        headers: {
          Authorization: `Token ${localStorageGetItem(STORAGE_KEYS.TOKEN)}`,
        },
      });
    return response.data;
  };

  const queryOptions = {
    queryKey: ['service-requests', statusFilter, typeWorkFilter],
    queryFn: async () => await getServiceRequestsList(statusFilter, typeWorkFilter),
  };

  return useQuery(queryOptions);
};
