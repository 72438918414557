import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { apiClient } from '@shared/api/base';
import { type AxiosPromise } from 'axios';
import { type IClientsList } from '@features/ClientsListTable/config/interfaces';
import { localStorageGetItem } from '@shared/lib/utils/localStorage';
import { STORAGE_KEYS } from '@pages/AuthPage/config/constants';

export const useClientsList = (page: number, pageSize: number):
    UseQueryResult<IClientsList, unknown> => {
  const getClients = async (page = 1, pageSize = 20): AxiosPromise<IClientsList> => {
    const response = await apiClient.get(`provider/customers/?page=${page}&page_size=${pageSize}`, {
      withCredentials: true,
      headers: {
        Authorization: `Token ${localStorageGetItem(STORAGE_KEYS.TOKEN)}`,
      },
    });
    return response.data;
  };

  const queryOptions = {
    queryKey: ['clients', page, pageSize],
    queryFn: async () => await getClients(page, pageSize),
  };

  return useQuery(queryOptions);
};
