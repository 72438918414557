// @ts-nocheck
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { useEffect, useState } from 'react';

export const useGeocodeService = () => {
  const map = useMap();
  const geocodeLibrary = useMapsLibrary('geocoding');
  const [geocodeService, setGeocodeService] = useState(null);

  useEffect(() => {
    if (!geocodeLibrary || !map) return;
    setGeocodeService(new geocodeLibrary.Geocoder());
  }, [geocodeLibrary, map]);

  return geocodeService;
};
