// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { Drawer } from '@mui/material';
import IconPlus from '@shared/assets/Icons/icon_plus_accent.svg';
import IconClose from '@shared/assets/Icons/icon_close.svg';
import { useMakeAppointments } from '@widgets/MakeAppointmentWidget/model/useMakeAppointments';
import { useParams } from 'react-router-dom';
import AddInvoice from '@widgets/MakeAppointmentWidget/ui/AddInvoice';
import { useFieldArray, useForm } from 'react-hook-form';
import TimePeriod from '@widgets/MakeAppointmentWidget/ui/TimePeriod';
import { DateTime } from 'luxon';
import { MAX_LIMIT_TIME_PERIOD } from '@widgets/MakeAppointmentWidget/config/constants';
import { useFetchProducts } from '@widgets/MakeAppointmentWidget/model/useFetchProducts';
import Invoice from '@widgets/MakeAppointmentWidget/ui/Invoice';
import { type IProduct } from '@widgets/MakeAppointmentWidget/config/interfaces';
import { ModalWindow } from '@widgets/CreateServiceRequestWidget/ui';
import { useUpdateAppointment } from '@widgets/MakeAppointmentWidget/model/useUpdateAppointment';
import { useFetchAppointment } from '@widgets/MakeAppointmentWidget/model/useFetchAppointment';
import { useAddProductsArray } from '@widgets/MakeAppointmentWidget/model/useAddProductsArray';
import { useServiceProviderProfile } from '@widgets/HeaderWidget/model/useServiceProviderProfile';
import { useTouchServiceRequest } from '@widgets/DetailServiceRequestWidget/model/useTouch';

interface IMakeAppointmentWidgetProps {
  isVisible: boolean
  setVisible: (boolean) => void
  appointment?: Record<string, string | number>
  isRescheduling: boolean
}

const MakeAppointmentWidget: React.FC<IMakeAppointmentWidgetProps> = ({ isVisible, setVisible, appointment, isRescheduling }) => {
  const { t } = useTranslation();
  const [isVisibleSuccessModal, setVisibleSuccessModal] = useState<boolean>(false);
  const [isVisibleCancelModal, setVisibleCancelModal] = useState<boolean>(false);
  const [isInvoiceVisible, setInvoiceVisible] = useState<boolean>(false);
  const [isSendFormDisabled, setSendFormDisabled] = useState(false);
  const [isAddTimePeriodDisabled, setAddTimePeriodDisabled] = useState(false);
  const [currentProduct, setCurrentProduct] = useState<IProduct>(null);
  const [localProducts, setLocalProducts] = useState([]);
  const { id } = useParams();

  const { data: profile } = useServiceProviderProfile();
  const { data: products, refetch: refetchProducts } = useFetchProducts(id as string);
  const { data: appointmentData, refetch } = useFetchAppointment(appointment?.id);

  const productItems = localProducts.length ? [...products, ...localProducts] : products;
  const subTotal = productItems?.length
    ? productItems?.reduce((accumulator, item) => {
      const quantity = item.quantity;
      const unitPrice = parseFloat(item.unit_price);
      return accumulator + (quantity * unitPrice);
    }, 0)
    : 0;
  const vatSum = profile?.vat_rate ? profile?.vat_rate * subTotal / 100 : 0;
  const { control, handleSubmit, register, watch, reset, setValue } = useForm({
    defaultValues: {
      service_request_id: id,
      no_confirmation_required: false,
      options: [
        {
          date: DateTime.local().toFormat('yyyy-MM-dd'),
          start_time: '',
          end_time: '',
          main_technician: '',
          technicians_ids: [],
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'options',
  });

  const formValues = watch();

  useEffect(() => {
    const areAllOptionsFilled = formValues.options?.every((period) => period.start_time && period.end_time && period.technicians_ids?.length > 0);
    const isDisableAddTimePeriod = formValues.options?.length === MAX_LIMIT_TIME_PERIOD ||
      formValues.no_confirmation_required;
    setAddTimePeriodDisabled(isDisableAddTimePeriod);
    setSendFormDisabled(areAllOptionsFilled);
  }, [formValues]);

  useEffect(() => {
    if (formValues.no_confirmation_required && formValues.options.length > 1) {
      formValues.options.slice(1).forEach((_, index) => { remove(index + 1); });
    }
  }, [formValues.no_confirmation_required, formValues.options, remove]);

  useEffect(() => {
    if (appointment) {
      remove();
      for (let i = 0; i < appointmentData?.options.length; i++) {
        append({
          date: appointmentData?.options[i].date,
          start_time: appointmentData?.options[i].start_time.substring(0, 5),
          end_time: appointmentData?.options[i].end_time.substring(0, 5),
          main_technician: appointmentData?.options[i].main_technician,
          technicians_ids: appointmentData?.options[i].technicians,
        });
      }
    }
  }, [append, appointmentData, setValue, isVisible]);

  const onSubmit = async data => {
    data?.options?.forEach(option => {
      const ids = [];
      option?.technicians_ids?.forEach(tech => {
        ids.push(tech.id);
      });
      option.technicians_ids = ids;
    });
    data?.options?.forEach(option => {
      option.main_technician = option.technicians_ids[0];
    });
    if (appointment && !isRescheduling) {
      await updateAppointment({ id: appointment?.id, newValues: data });
      touchServiceRequest();
      await refetch();
    } else {
      makeAppointment(data);
      addProducts(localProducts);
      touchServiceRequest();
      await refetchProducts();
    }
  };

  const onSubmitSuccess = (): void => {
    setVisible(false);
    setVisibleSuccessModal(true);
    setLocalProducts([]);
  };

  const { mutate: makeAppointment } = useMakeAppointments(id, onSubmitSuccess);
  const { mutateAsync: updateAppointment } = useUpdateAppointment(id, onSubmitSuccess);
  const { mutate: addProducts } = useAddProductsArray();
  const { mutate: touchServiceRequest } = useTouchServiceRequest(id);

  return (
    <>
      <Drawer
        open={isVisible}
        onClose={() => {
          setVisibleCancelModal(true);
        }}
        anchor="right"
      >
        <button
          className="button button--small button--drawer"
          style={{ position: 'absolute', zIndex: 15 }}
          onClick={() => {
            setVisibleCancelModal(true);
          }}>
          <img src={IconClose} width="32" height="32" alt="close"/>
        </button>
        <form className="make-appointment_form" onSubmit={handleSubmit(onSubmit)}>
          <div className="drawer__inner"
            style={{
              position: 'relative',
              width: '614px',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <h2 style={{ marginBottom: '24px' }}>{appointment ? t('makeAppointment.edit-appointment') : t('makeAppointment.make-appointment')}</h2>
              <div className="time-period_container">
                {fields.map((field, index) => (
                  <TimePeriod
                    key={field.id}
                    control={control}
                    setValue={setValue}
                    index={index}
                    remove={remove}
                    timePeriods={formValues.options}
                    options={field}
                    hasAppointment={!!appointment}
                  />
                ))}
              </div>
              <div className="form__row">
                {formValues.options?.length !== MAX_LIMIT_TIME_PERIOD && (<button type="button"
                  className="button button--text-small"
                  style={{ marginTop: '24px', paddingLeft: 0 }}
                  onClick={() => {
                    if (!isAddTimePeriodDisabled) {
                      append({
                        date: DateTime.local().toFormat('yyyy-MM-dd'),
                        start_time: '',
                        end_time: '',
                        main_technician: '',
                        technicians_ids: [],
                      },
                      );
                    }
                  }}
                >
                  <img src={IconPlus} width="20" height="20" alt="Add a time period"/>
                  {t('makeAppointment.add-time-period')}
                </button>)}
              </div>
              <div className="form__row" style={{ marginBottom: '24px' }}>
                <input
                  type="checkbox"
                  id="no_confirmation_required"
                  {...register('no_confirmation_required')}
                />
                <label htmlFor="no_confirmation_required">
                  {t('makeAppointment.no-confirmation')}
                </label>
              </div>
              <div className="form__label" style={{ marginBottom: '12px' }}>{t('makeAppointment.price-quote')}</div>
              <div className="product-items">
                {productItems?.length > 0 && (
                  <div className="products_header">
                    <div className="products_header-service">{t('invoice.service-item')}</div>
                    <div className="products_header-qty">{t('invoice.qty')}</div>
                    <div className="products_header-price">{t('invoice.price')}</div>
                    <div className="products_header-empty" />
                  </div>
                )}
                {productItems?.length > 0 && productItems.map((product, index) => <Invoice
                  setVisibleForm={setInvoiceVisible}
                  setProduct={setCurrentProduct}
                  product={product}
                  localProducts={localProducts}
                  setLocalProducts={setLocalProducts}
                  key={index} />)}
              </div>
              <div className="form__row">
                <button
                  className="button button--text-small"
                  style={{ paddingLeft: 0 }}
                  type="button"
                  onClick={() => { setInvoiceVisible(true); setCurrentProduct(null); }}
                >
                  <img src={IconPlus} width="20" height="20" alt="Add a time period"/>
                  {t('makeAppointment.add-invoice-item')}
                </button>
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="table table--total-sum" style={{ marginBottom: '48px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '12px' }}>
                  <div className="text-gray-light">{t('invoice.vat')} {profile?.vat_rate ? profile?.vat_rate : 0}%</div>
                  <div className="text-gray-light">{vatSum}€</div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div className="total-sum">{t('invoice.total-sum')}</div>
                  <div className="total-sum">
                    {subTotal + vatSum}€
                  </div>
                </div>
              </div>
              <div className="buttons__group">
                <button type="button" className="button button--border" onClick={() => { setVisibleCancelModal(true); }}>
                  {t('makeAppointment.cancel')}
                </button>
                <button className="button button--default" type="submit"
                  disabled={!isSendFormDisabled}>
                  {t('makeAppointment.send')}
                </button>
              </div>
            </div>
          </div>
        </form>
        <AddInvoice
          isVisible={isInvoiceVisible}
          setVisible={setInvoiceVisible}
          product={currentProduct}
          setLocalProducts={setLocalProducts}
        />
      </Drawer>
      <ModalWindow
        isVisible={isVisibleSuccessModal}
        setIsVisible={setVisibleSuccessModal}
        title={t('modals.make-appointment-title')}
        subtitle={t('modals.make-appointment-subtitle')}
        description={formValues.no_confirmation_required ? t('modals.make-appointment-description') : t('modals.make-appointment-description2')}
        secondBtnText={t('modals.make-appointment-yes')}
        secondBtnAction={() => {
          setVisibleSuccessModal(false);
          setVisible(false);
          reset();
        }}
      />
      <ModalWindow
        isVisible={isVisibleCancelModal}
        setIsVisible={setVisibleCancelModal}
        title={appointment ? t('modals.cancel-appointment-title') : t('modals.cancel-appointment-title2')}
        subtitle={t('modals.make-appointment-yes')}
        description={appointment ? t('modals.cancel-appointment-description') : t('modals.cancel-appointment-description2')}
        firstBtnText={t('modals.cancel-appointment-no')}
        firstBtnAction={() => {
          setVisibleCancelModal(false);
        }}
        secondBtnText={t('modals.cancel-appointment-yes')}
        secondBtnAction={() => {
          setVisibleCancelModal(false);
          setLocalProducts([]);
          setCurrentProduct(null);
          setVisible(false);
          reset();
        }}
      />
    </>
  );
};

export default MakeAppointmentWidget;
