// @ts-nocheck
import { useMutation, type UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { type AxiosPromise, type AxiosResponse } from 'axios';
import { apiClient } from '@shared/api/base';

import { type IServiceRequest } from '../config/interfaces';

export const useServiceRequestUpdate = (id: IServiceRequest['id'],
  setCategory: (value: IServiceRequest['category']) => void): UseMutationResult<
  unknown,
  unknown,
  IServiceRequest,
  unknown
  > => {
  const updateServiceRequest = async (serviceRequestId: string,
    category: IServiceRequest['category'],
  ): AxiosPromise<AxiosResponse> => {
    return await apiClient.patch(
      `/provider/service-requests/${serviceRequestId?.id}/`,
      { category: serviceRequestId.selectedValue },
    );
  };
  const queryClient = useQueryClient();
  const mutationOptions: {
    mutationFn: (serviceRequestId: string, payload: IServiceRequest['category']) => AxiosPromise<AxiosResponse>
    onSuccess: (data: AxiosResponse<IServiceRequest>) => void
    onError: (error: unknown) => void
  } = {
    mutationFn: updateServiceRequest,
    onSuccess: (data) => {
      setCategory(data?.data?.category);
      queryClient.setQueryData(['service-request', id], (detailServiceRequest) => ({
        ...detailServiceRequest,
        category: data?.data?.category,
      }));
    },
    onError: () => {},
  };

  return useMutation(mutationOptions);
};
