// @ts-nocheck
import React, { useState } from 'react';
import { Drawer } from '@mui/material';
import IconClose from '@shared/assets/Icons/icon_close.svg';
import { useSendInvoice } from '@widgets/CreateInvoiceWidget/model/useSendInvoice';
import { useParams } from 'react-router-dom';
import { useServiceRequest } from '@widgets/DetailServiceRequestWidget/model/useServiceRequest';
import { ModalWindow } from '@widgets/CreateServiceRequestWidget/ui';
import { useTranslation } from 'react-i18next';
import { useServiceProviderProfile } from '@widgets/HeaderWidget/model/useServiceProviderProfile';
import { useUpdateInvoice } from '@widgets/CreateInvoiceWidget/model/useUpdateInvoice';

interface IInvoiceWidgetProps {
  isVisible: boolean
  setVisible: (boolean) => void
  documentKey?: string
  setDocument: (boolean) => void
}

const ManualInvoice: React.FC<IInvoiceWidgetProps> = ({ isVisible, setVisible, documentKey, setDocument, setVisibleSuccess }) => {
  const { t } = useTranslation();
  const [subtotal, setSubtotal] = useState(0);

  const onSuccessSendInvoice = () => {
    setVisible(false);
    setDocument(null);
    setVisibleSuccess(true);
  };

  const { id } = useParams();
  const { data: serviceRequest } = useServiceRequest(id as string);
  const { data: profile } = useServiceProviderProfile();
  const { mutateAsync: sendInvoice } = useSendInvoice(id, onSuccessSendInvoice, serviceRequest?.invoice_id);

  const onSuccessUpdateInvoice = async () => {
    await sendInvoice(serviceRequest?.invoice_id);
  };

  const { mutateAsync: updateInvoice } = useUpdateInvoice(serviceRequest?.invoice_id, onSuccessUpdateInvoice);

  const handleSendInvoice = async (): Promise<void> => {
    const invoiceUpdateRequest = {
      subtotal,
      key: documentKey,
      records: null,
      invoice_id: serviceRequest?.invoice_id,
    };
    await updateInvoice(invoiceUpdateRequest);
  };

  const vatAmount = profile?.vat_rate ? parseFloat(subtotal * (profile?.vat_rate / 100)) : 0;
  const totalSum = vatAmount + parseFloat(subtotal) || 0;

  const onChangeSubtotal = (e) => {
    setSubtotal(e.target.value);
  };

  return (
    <>
      <Drawer
        open={isVisible}
        onClose={() => {
          setVisible(false);
        }}
        anchor="right"
      >
        <button
          className="button button--small button--drawer"
          style={{ position: 'absolute', zIndex: 15 }}
          onClick={() => {
            setVisible(false);
          }}>
          <img src={IconClose} width="32" height="32" alt="close"/>
        </button>
        <div className="preliminary-invoice_container">
          <h2 style={{ marginBottom: '24px' }}>{t('invoice.subtotal-title')}</h2>
          <div className="form__row__group">
            <div className="form__row">
              <div className="form__label">
                {t('invoice.subtotal-label')}<span className="required" style={{ color: '#e03137' }}>*</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="text"
                  name="subtotal"
                  placeholder={t('invoice.subtotal-label')}
                  onChange={onChangeSubtotal}
                  value={subtotal}
                />
                <span style={{ fontSize: '16px', fontWeight: 600, marginLeft: '8px' }}>€</span>
              </div>
            </div>
          </div>
          <div className="product-items_total" style={{ marginBottom: '24px' }}>
            <div className="product-items_total-vat">
              <span>{t('invoice.vat')} {profile?.vat_rate ? profile?.vat_rate : 0}%</span>
              <span>{parseFloat(vatAmount).toFixed(2)}€</span>
            </div>
            <div className="product-items_total-totalsum">
              <span>{t('invoice.total-sum')}</span>
              <span>{parseFloat(totalSum).toFixed(2)}€</span>
            </div>
          </div>
          <div className="buttons__group" style={{ padding: '32px 0' }}>
            <button
              type="button"
              className="button button--border"
              onClick={() => { setVisible(false); }}
            >
              {t('invoice.cancel')}
            </button>
            <button
              className="button button--default"
              type="submit"
              disabled={!subtotal}
              onClick={handleSendInvoice}
            >
              {t('invoice.send-request-payment')}
            </button>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default ManualInvoice;
