import React from 'react';

import { TechniciansListWidget } from '../../widgets';

const TechniciansPage: React.FC = () => {
  return (
    <TechniciansListWidget />
  );
};

export default TechniciansPage;
