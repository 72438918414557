// @ts-nocheck
import { useMutation, type UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { apiClient } from '@shared/api/base';
import { type AxiosPromise, type AxiosResponse } from 'axios';
import { type IMakeAppointmentsRequest } from '@widgets/MakeAppointmentWidget/config/interfaces';

export const useUpdateAppointment = (serviceRequestId: string, onSubmitSuccess: () => void): UseMutationResult<unknown, unknown, IMakeAppointmentsRequest, unknown> => {
  const updateAppointment = async (id: string, newValues: IMakeAppointmentsRequest): AxiosPromise<AxiosResponse> => {
    return await apiClient.put(
      `provider/appointments/${id}/`,
      newValues,
    );
  };
  const queryClient = useQueryClient();
  const mutationOptions: {
    onError: (error: unknown) => void
    mutationFn: (payload: IMakeAppointmentsRequest) => AxiosPromise<AxiosResponse>
    onSuccess: (data: unknown) => void
  } = {
    mutationFn: async ({ id, newValues }: { id: string, newValues: IMakeAppointmentsRequest }) => await updateAppointment(id, newValues),
    onSuccess: () => {
      onSubmitSuccess();
      void queryClient.invalidateQueries({ queryKey: ['service-request', serviceRequestId] });
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
    },
  };

  return useMutation(mutationOptions);
};
