import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { type AxiosPromise, type AxiosResponse } from 'axios';
import { apiClient } from '@shared/api/base';
import { localStorageGetItem } from '@shared/lib/utils/localStorage';

import { STORAGE_KEYS } from '../config/constants';
import { type IServiceRequestDraft, type IServiceRequestDraftResponse } from '../config/interfaces';

export const useCreateServiceRequestDraft = ({ onDraftSuccess }: { onDraftSuccess: () => void }):
  UseMutationResult<
    AxiosResponse<AxiosResponse<IServiceRequestDraftResponse>>,
    unknown,
    IServiceRequestDraft,
    unknown
    > => {
  const createServiceRequestDraft = async (payload: IServiceRequestDraft):
    AxiosPromise<AxiosResponse<IServiceRequestDraftResponse>> => {
    return await apiClient.post(
      '/provider/service-requests/',
      payload, {
        headers: {
          Authorization: `Token ${localStorageGetItem(STORAGE_KEYS.TOKEN)}`,
        },
      },
    );
  };

  const mutationOptions: {
    mutationFn: (payload: IServiceRequestDraft) => AxiosPromise<AxiosResponse>
    onSuccess: () => void
    onError: (error: unknown) => void
  } = {
    mutationFn: createServiceRequestDraft,
    onSuccess: () => {
      onDraftSuccess();
    },
    onError: () => {},
  };

  return useMutation(mutationOptions);
};
