import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { apiClient } from '@shared/api/base';
import { type AxiosPromise } from 'axios';
import { localStorageGetItem } from '@shared/lib/utils/localStorage';

import { STORAGE_KEYS } from '../config/constants';

import { type IServiceRequestClientsList } from '../config/interfaces';

export const useGetClientsList = (page?: number, pageSize?: number):
    UseQueryResult<IServiceRequestClientsList, unknown> => {
  const getClients = async (page = 1, pageSize = 150): AxiosPromise<IServiceRequestClientsList> => {
    const response = await apiClient.get(`provider/customers/?page=${page}&page_size=${pageSize}`, {
      withCredentials: true,
      headers: {
        Authorization: `Token ${localStorageGetItem(STORAGE_KEYS.TOKEN)}`,
      },
    });
    return response.data;
  };

  const queryOptions = {
    queryKey: ['clients', page, pageSize],
    queryFn: async () => await getClients(page, pageSize),
    onSuccess: () => {},
    onError: () => {},
  };

  return useQuery(queryOptions);
};
