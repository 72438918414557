import { type ITypeOfWork } from './interfaces';

export const PERSON_TITLE = {
  mr: 'personTitle.mr',
  mrs: 'personTitle.mrs',
  ms: 'personTitle.ms',
};

export const TYPE_OF_WORK_LIST: ITypeOfWork[] = [
  {
    id: '0',
    value: 'empty',
    label: 'typeOfWork.empty',
  },
  {
    id: '1',
    value: 'MAINTENANCE',
    label: 'typeOfWork.maintenance',
  },
  {
    id: '2',
    value: 'REPAIR',
    label: 'typeOfWork.repair',
  },
  {
    id: '3',
    value: 'PROJECT',
    label: 'typeOfWork.project',
  },
  {
    id: '4',
    value: 'OTHER',
    label: 'typeOfWork.other',
  },
];
