// @ts-nocheck
import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { apiClient } from '@shared/api/base';
import { queryClient } from '@shared/api/query-client';
import { type AxiosPromise, type AxiosResponse } from 'axios';

export const useConfirmPayment = (invoiceId: string, serviceRequestId, onSuccess): UseMutationResult<unknown, unknown, unknown, unknown> => {
  const confirmPayment = async (): AxiosPromise<AxiosResponse> => {
    return await apiClient.post(
      `provider/invoices/${invoiceId}/manual-pay/`,
    );
  };

  const mutationOptions: {
    onError: (error: unknown) => void
    mutationFn: (payload: string) => AxiosPromise<AxiosResponse>
    onSuccess: (data: unknown) => void
  } = {
    mutationFn: confirmPayment,
    onSuccess: (data) => {
      void queryClient.invalidateQueries({ queryKey: ['service-request', serviceRequestId] });
      queryClient.setQueryData(['get-invoice', invoiceId], () => ({
        ...data?.data,
      }));
      queryClient.setQueryData(['service-request', serviceRequestId], (detailServiceRequest) => ({
        ...detailServiceRequest,
        status: 'PAID',
      }));
      onSuccess();
    },
    onError: () => {
    },
  };

  return useMutation(mutationOptions);
};
