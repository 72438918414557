import React from 'react';
import classNames from 'classnames/bind';
import { Modal } from '@mui/material';

import closeIcon from '@shared/assets/Icons/icon_close.svg';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface IModalWindowProps {
  isVisible: boolean
  setIsVisible: (arg: boolean) => void
  title: string
  subtitle?: string
  description: string
  firstBtnText?: string
  secondBtnText: string
  firstBtnAction?: () => void
  // eslint-disable-next-line @typescript-eslint/ban-types
  secondBtnAction: Function
}

const ModalWindow: React.FC<IModalWindowProps> = ({
  isVisible,
  setIsVisible,
  title,
  subtitle,
  description,
  firstBtnText,
  secondBtnText,
  firstBtnAction,
  secondBtnAction,
}) => {
  return (
    <Modal
      open={isVisible}
      onClose={(): void => { setIsVisible(false); }}
    >
      <div className={cx('modal')}>
        <div className={cx('modal-container')}>
          <button
            className={cx('button', 'button--small', 'modal-close', 'modal-exit')}
            onClick={() => {
              setIsVisible(false);
            }}
          >
            <img src={closeIcon} width="32" height="32" alt="close" />
          </button>
          <h2>{title}</h2>
          {subtitle && (
            <h4>{subtitle}</h4>
          )}
          <div className={cx('modal-text')}>
            <p>{description}</p>
          </div>
          <div className={cx('buttons__group')}>
            {firstBtnText && (<button
              className={cx('button', 'button--border')}
              onClick={() => {
                if (firstBtnAction) {
                  firstBtnAction();
                }
              }}
            >
              {firstBtnText}
            </button>)}
            <button
              className={cx('button', 'button--default')}
              onClick={() => {
                secondBtnAction();
              }}
            >
              {secondBtnText}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalWindow;
