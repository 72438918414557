import { useMutation, type UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { type AxiosPromise, type AxiosResponse } from 'axios';
import { apiClient } from '@shared/api/base';
import { localStorageGetItem } from '@shared/lib/utils/localStorage';

import { STORAGE_KEYS } from '../config/constants';

export const useCreateServiceRequestConfirm = ({ onConfirmSuccess, serviceRequestId }:
  { onConfirmSuccess: () => void, serviceRequestId: string }): UseMutationResult<
    AxiosResponse<AxiosResponse>,
    unknown,
    object,
    unknown> => {
  const createServiceRequestConfirm = async (payload: object): AxiosPromise<AxiosResponse> => {
    return await apiClient.post(
      `/provider/service-requests/${serviceRequestId}/confirm/`,
      payload,
      {
        headers: {
          Authorization: `Token ${localStorageGetItem(STORAGE_KEYS.TOKEN)}`,
        },
      },
    );
  };
  const queryClient = useQueryClient();
  const mutationOptions: {
    mutationFn: (payload: object) => AxiosPromise<AxiosResponse>
    onSuccess: () => void
    onError: (error: unknown) => void
  } = {
    mutationFn: createServiceRequestConfirm,
    onSuccess: () => {
      onConfirmSuccess();
      void queryClient.invalidateQueries({ queryKey: ['service-requests'] });
    },
    onError: () => {},
  };

  return useMutation(mutationOptions);
};
