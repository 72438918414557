import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { apiClient } from '@shared/api/base';
import { type AxiosPromise, type AxiosResponse } from 'axios';
import { localStorageGetItem } from '@shared/lib/utils/localStorage';
import { STORAGE_KEYS } from '@pages/AuthPage/config/constants';
import { type IServiceProviderProfile } from '@widgets/HeaderWidget/config/interfaces';

export const useServiceProviderProfile = ():
    UseQueryResult<IServiceProviderProfile, unknown> => {
  const getServiceProviderProfile = async (): AxiosPromise<IServiceProviderProfile> => {
    const response = await apiClient.get('provider/profile/', {
      withCredentials: true,
      headers: {
        Authorization: `Token ${localStorageGetItem(STORAGE_KEYS.TOKEN)}`,
      },
    });
    return response.data;
  };

  const queryOptions = {
    queryKey: ['service-provider-profile'],
    queryFn: getServiceProviderProfile,
    onSuccess: (data: AxiosResponse<IServiceProviderProfile>) => {
      // eslint-disable-next-line no-console
      console.log(data);
    },
    onError: (error: unknown) => {
      // eslint-disable-next-line no-console
      console.error(error);
    },
  };

  return useQuery(queryOptions);
};
