import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { apiClient } from '@shared/api/base';
import { type AxiosPromise, type AxiosResponse } from 'axios';
import { type IProduct } from '@widgets/MakeAppointmentWidget/config/interfaces';

export const useFetchProducts = (id: string): UseQueryResult<IProduct, unknown> => {
  const getProductsServiceRequest = async (id: string): AxiosPromise<AxiosResponse> => {
    const response = await apiClient.get(`provider/products/?service_request_id=${id}`, {
      withCredentials: true,
    });
    return response.data;
  };

  return useQuery(
    {
      queryKey: ['products', id],
      queryFn: async () => await getProductsServiceRequest(id),
      refetchOnMount: true,
    });
};
