// @ts-nocheck
import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { apiClient } from '@shared/api/base';
import { queryClient } from '@shared/api/query-client';
import { type AxiosPromise, type AxiosResponse } from 'axios';
import { type IAddInvoiceRequest } from '@widgets/MakeAppointmentWidget/config/interfaces';

export const useAddProduct = (id, resetForm, setVisibleForm, invoiceId): UseMutationResult<unknown, unknown, IAddInvoiceRequest, unknown> => {
  const addProduct = async (invoice: IAddInvoiceRequest): AxiosPromise<AxiosResponse> => {
    return await apiClient.post(
      'provider/products/',
      invoice,
    );
  };

  const mutationOptions: {
    onError: (error: unknown) => void
    mutationFn: (payload: IAddInvoiceRequest) => AxiosPromise<AxiosResponse>
    onSuccess: (data: unknown) => void
  } = {
    mutationFn: addProduct,
    onSuccess: (data) => {
      resetForm();
      setVisibleForm(false);
      queryClient.setQueryData(['products', id], (oldProducts) => {
        return [...oldProducts, data?.data];
      });

      void queryClient.invalidateQueries({ queryKey: ['products', id] });
      void queryClient.invalidateQueries({ queryKey: ['get-invoice', invoiceId] });
    },
    onError: () => {
    },
  };

  return useMutation(mutationOptions);
};
