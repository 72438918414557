import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { apiClient } from '@shared/api/base';
import { type AxiosPromise, type AxiosResponse } from 'axios';
import { type IInvoiceRequest } from '@widgets/CreateInvoiceWidget/config/interfaces';

export const useGetInvoice = (id: string):
  UseQueryResult<IInvoiceRequest, unknown> => {
  const getInvoice = async (invoiceId: string): AxiosPromise<IInvoiceRequest> => {
    const response = await apiClient.get(`provider/invoices/${invoiceId}/`, {
      withCredentials: true,
    });
    return response.data;
  };

  const queryOptions = {
    queryKey: ['get-invoice', id],
    queryFn: async () => await getInvoice(id),
    onSuccess: (data: AxiosResponse<IInvoiceRequest>) => {
      // eslint-disable-next-line no-console
      console.log(data);
    },
    onError: (error: unknown) => {
      // eslint-disable-next-line no-console
      console.error(error);
    },
    enabled: !!id,
    refetchOnMount: true,
  };

  return useQuery(queryOptions);
};
