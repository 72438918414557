// @ts-nocheck
import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { DateTime } from 'luxon';
import { type IServiceRequestsList } from '@pages/DashboardPage/config/interfaces';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { Box, type SxProps } from '@mui/material';
import { REQUEST_STATUS } from '@widgets/DashboardListViewWidget/config/constants';
import { NavLink } from 'react-router-dom';
import { PERSON_TITLE } from '@widgets/DetailServiceRequestWidget/config/constants';
import { REQUEST_STATUS_LIST, TYPE_OF_WORK_LIST } from '@pages/DashboardPage/config/constants';
import IconEmergency from "@shared/assets/Icons/icon_emergency.svg";

interface ITableCellProps {
    text: string
    align?: 'left' | 'right' | 'center' | 'inherit' | 'justify' | undefined
    sx?: SxProps | undefined
}

interface IDashboardListViewProps {
    serviceRequestList: IServiceRequestsList[] | undefined
}

const DashboardListViewWidget: React.FC<IDashboardListViewProps> = ({ serviceRequestList }) => {
  const { t } = useTranslation();
  const TableTitleItem: React.FC<ITableCellProps> = ({ text, align = 'center' }) => {
    return (
      <TableCell
        align={align}
      >
        {text}
      </TableCell>
    );
  };

  return (
    <Box className="tab-content">
      <Box className="simple-table-container">
        <TableContainer
          component={Paper}
          className="simple-table-wrapper"
        >
          <Table
            className="simple-table"
            aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableTitleItem align="left" text={t('dashboard.requestID')}/>
                <TableTitleItem align="left" text={t('dashboard.client-name')} />
                <TableTitleItem text={t('dashboard.type-of-work')} />
                <TableTitleItem align="left" text={t('detailServiceRequest.problem-description')} />
                <TableTitleItem text={t('dashboard.request-status')} />
                <TableTitleItem text={t('dashboard.how-far')}/>
                <TableTitleItem text={t('dashboard.date')} />
              </TableRow>
            </TableHead>
            <TableBody>
              {serviceRequestList?.sort((a, b) => b.mark - a.mark).map((serviceRequest) => {
                const { id, created, number, customer, category, emergency, status, mark, distance, description } = serviceRequest;
                const diff = DateTime.fromISO(created).diff(DateTime.now(), ['days']);
                const createdDate = Math.abs(diff.toObject().days as number) > 4
                  ? DateTime.fromISO(created).toLocaleString()
                  : DateTime.now().setLocale('en').plus(diff).toRelativeCalendar();
                return (
                  <TableRow
                    component={NavLink}
                    key={id}
                    to={`/service-request/detail/${id}`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="left" className="text-gray dashboard-list">
                      <div className="client-id">
                        <div style={{ marginRight: '16px' }}>{'#'}{number}</div>
                        {emergency && status === 'NEW' &&
                          (<><div className="indicator-emergency">{t('createServiceRequest.emergency')}</div><img src={IconEmergency} width="20" height="20" alt=""/></>)
                        }
                        {mark &&
                          (<div className="indicator-need-action">{t('detailServiceRequest.action-required')}</div>)
                        }
                      </div>
                    </TableCell>
                    <TableCell
                      scope="row"
                    >
                      <Box className="person">
                        <Box className="person-avatar">
                          {customer?.first_name[0]}
                          {customer?.last_name[0]}
                        </Box>
                        {customer?.person_title && (t(PERSON_TITLE[customer?.person_title]) + ' ')}
                        {customer?.first_name}
                        {' '}
                        {customer?.last_name}
                      </Box>
                    </TableCell>
                    {/* <TableCell align="center" className="status-color"> */}
                    {/*  {customer?.status} */}
                    {/* </TableCell> */}
                    <TableCell align="center">
                      {category && t(TYPE_OF_WORK_LIST.find(type => type.value === category).label)}
                    </TableCell>
                    <TableCell align="left">
                      <div className="problem-description">
                        {description}
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <div className={`line-indicator line-indicator--${REQUEST_STATUS[status].style}`}>
                        {status && t(REQUEST_STATUS_LIST.find(type => type.value === status).label)}
                      </div>
                    </TableCell>
                    <TableCell align="center" className="text-gray">
                      {distance.toFixed(1)}{' km'}
                    </TableCell>
                    <TableCell align="center">
                      {createdDate}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default DashboardListViewWidget;
