import React from 'react';

import { ClientsListWidget } from '../../widgets';

const ClientsPage: React.FC = () => {
  return (
    <ClientsListWidget />
  );
};

export default ClientsPage;
