import { type MutateFunction, useMutation, type UseMutationResult } from '@tanstack/react-query';
import { type AxiosPromise, type AxiosResponse } from 'axios';
import { apiClient } from '@shared/api/base';
import { localStorageGetItem } from '@shared/lib/utils/localStorage';

import { type IUploadMediaProcess } from '@widgets/CreateServiceRequestWidget/config/interfaces';

import { STORAGE_KEYS } from '../config/constants';

interface IUploadMediaStart {
  file_name: string
  service_request_id: string
}

export const useUploadMediaStart = ({
  processFunction,
  file,
  setKeyFunction,
}: { processFunction: (...args: Parameters<MutateFunction<unknown, unknown, IUploadMediaProcess, unknown>>) => void
  file: File | null
  setKeyFunction: (value: (((prevState: string) => string) | string)) => void }): UseMutationResult<
  unknown,
  unknown,
  IUploadMediaStart,
  unknown
  > => {
  const uploadMediaStart = async (payload: IUploadMediaStart): AxiosPromise<AxiosResponse> => {
    return await apiClient.post(
      'provider/upload/',
      payload, {
        headers: {
          Authorization: `Token ${localStorageGetItem(STORAGE_KEYS.TOKEN)}`,
        },
      },
    );
  };

  const mutationOptions: {
    mutationFn: (payload: IUploadMediaStart) => AxiosPromise<AxiosResponse>
    onSuccess: (data: AxiosResponse) => void
    onError: (error: unknown) => void
  } = {
    mutationFn: uploadMediaStart,
    onSuccess: (data: AxiosResponse) => {
      setKeyFunction((data.data.fields.key) as string);
      const processRequest = {
        ...data.data.fields,
        file,
        url: data.data.url,
      } as IUploadMediaProcess;
      processFunction(processRequest);
    },
    onError: () => {},
  };

  return useMutation(mutationOptions);
};
