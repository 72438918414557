import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { apiClient } from '@shared/api/base';
import { type AxiosPromise, type AxiosResponse } from 'axios';
import { localStorageGetItem } from '@shared/lib/utils/localStorage';
import { STORAGE_KEYS } from '@pages/AuthPage/config/constants';
import { type ITechnicianProfile } from '@widgets/TechnicianProfileWidget/config/interfaces';

export const useTechnicianProfile = (id: string):
  UseQueryResult<ITechnicianProfile, unknown> => {
  const getTechnicianProfile = async (id: string): AxiosPromise<ITechnicianProfile> => {
    const response = await apiClient.get(`provider/technicians/${id}/`, {
      withCredentials: true,
      headers: {
        Authorization: `Token ${localStorageGetItem(STORAGE_KEYS.TOKEN)}`,
      },
    });
    return response.data;
  };

  const queryOptions = {
    queryKey: ['technician-profile', id],
    queryFn: async () => await getTechnicianProfile(id),
    onSuccess: (data: AxiosResponse<ITechnicianProfile>) => {
      // eslint-disable-next-line no-console
      console.log(data);
    },
    onError: (error: unknown) => {
      // eslint-disable-next-line no-console
      console.error(error);
    },
  };

  return useQuery(queryOptions);
};
