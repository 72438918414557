import React from 'react';
import classNames from 'classnames/bind';

import atysSad from '../../shared/assets/Images/atys_sad.svg';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const NotFoundPage: React.FC = () => {
  return (
    <div className={cx('container')}>
      <div className={cx('container__message')}>
        <div className={cx('container__message__img')}>
          <img src={atysSad} alt="Atys sad"/>
        </div>
        <div className={cx('container__message__text')}>
          Page not found
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
