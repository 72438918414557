// @ts-nocheck
import React from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { type IProduct } from '@widgets/MakeAppointmentWidget/config/interfaces';
import IconEdit from '@shared/assets/Icons/icon_edit.svg';
import IconDelete from '@shared/assets/Icons/icon_delete.svg';
import { UNITS } from '@widgets/MakeAppointmentWidget/config/constants';
import { useDeleteProduct } from '@widgets/MakeAppointmentWidget/model/useDeleteProduct';
import { useParams } from 'react-router-dom';

const Invoice: React.FC = ({ product, setProduct, setVisibleForm, localProducts, setLocalProducts, isNotEdit }: IProduct) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { name, quantity, unit, unit_price } = product;
  const shortLabel = UNITS.find(unitItem => unitItem.value === unit).shortLabel;
  const { mutate: deleteProduct } = useDeleteProduct(product.id, id);
  const handleDelete = () => {
    if (product?.isLocal) {
      const newProducts = localProducts.filter(productItem => productItem.id !== product.id);
      setLocalProducts(newProducts);
    } else {
      deleteProduct();
    }
  };

  const handleEdit = () => {
    setProduct(product);
    setVisibleForm(true);
  };

  return (
    <div className="product-item">
      <div className="product-item_service">{name}</div>
      <div className="products-item_-qty">{quantity}/{t(shortLabel)}</div>
      <div className="products-item_price">
        <div className="products-item_price-main">{unit_price * quantity}€</div>
        <div className="products-item_price-detail">{unit_price}€/{t(shortLabel)}</div>
      </div>
      {!isNotEdit && (<div className="products-item_edit">
        <button type="button" className="button button--small product-item_edit-btn">
          <img src={IconEdit} onClick={handleEdit} width="32" height="32" alt="edit"/>
        </button>
        <button type="button" className="button button--small product-item_delete-btn">
          <img src={IconDelete} onClick={handleDelete} width="32" height="32" alt="delete"/>
        </button>
      </div>)}
    </div>
  );
};

export default Invoice;
