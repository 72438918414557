// @ts-nocheck
import { useMutation, type UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { apiClient } from '@shared/api/base';
import { type AxiosPromise, type AxiosResponse } from 'axios';
import { type IInvoiceRequest } from '@widgets/CreateInvoiceWidget/config/interfaces';
import {queryClient} from "@shared/api/query-client";

export const useSendInvoice = (serviceRequestId, onSuccess, invoiceId): UseMutationResult<unknown, unknown, IInvoiceRequest, unknown> => {
  const sendInvoice = async (): AxiosPromise<AxiosResponse> => {
    return await apiClient.post(
      `/provider/invoices/${invoiceId}/send/`,
    );
  };
  const queryClient = useQueryClient();
  const mutationOptions: {
    onError: (error: unknown) => void
    mutationFn: (payload: IInvoiceRequest) => AxiosPromise<AxiosResponse>
    onSuccess: (data: unknown) => void
  } = {
    mutationFn: sendInvoice,
    onSuccess: () => {
      queryClient.setQueryData(['service-request', serviceRequestId], (detailServiceRequest) => ({
        ...detailServiceRequest,
        status: 'COMPLETED',
      }));
      void queryClient.invalidateQueries({ queryKey: ['get-invoice', invoiceId] });
      onSuccess();
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
    },
  };

  return useMutation(mutationOptions);
};
