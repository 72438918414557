import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { type AxiosPromise, type AxiosResponse } from 'axios';
import { apiClient } from '@shared/api/base';
import { localStorageGetItem } from '@shared/lib/utils/localStorage';

import { STORAGE_KEYS } from '../config/constants';
import { type IServiceRequest } from '../config/interfaces';

export const useCreateServiceRequest = ({ onSuccess, serviceRequestId }:
  { onSuccess: () => void, serviceRequestId: string }): UseMutationResult<
  unknown,
  unknown,
  IServiceRequest,
  unknown
  > => {
  const createServiceRequest = async (payload: IServiceRequest): AxiosPromise<AxiosResponse> => {
    return await apiClient.put(
      `/provider/service-requests/${serviceRequestId}/`,
      payload, {
        headers: {
          Authorization: `Token ${localStorageGetItem(STORAGE_KEYS.TOKEN)}`,
        },
      },
    );
  };

  const mutationOptions: {
    mutationFn: (payload: IServiceRequest) => AxiosPromise<AxiosResponse>
    onSuccess: (data: AxiosResponse) => void
    onError: (error: unknown) => void
  } = {
    mutationFn: createServiceRequest,
    onSuccess: () => {
      onSuccess();
    },
    onError: () => {},
  };

  return useMutation(mutationOptions);
};
