// @ts-nocheck
import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { Drawer } from '@mui/material';
import IconClose from '@shared/assets/Icons/icon_close.svg';
import Invoice from '@widgets/MakeAppointmentWidget/ui/Invoice';
import { useGetInvoice } from '@widgets/CreateInvoiceWidget/model/useGetInvoice';
import { useGenerateReport } from '@widgets/DetailServiceRequestWidget/model/useGenerateReport';
import { useParams } from 'react-router-dom';
import { TIME_TYPES } from '@shared/config/common/constants';

interface IInvoiceWidgetProps {
  isVisible: boolean
  setVisible: (boolean) => void
  invoiceId: string
}

const InvoiceWidget: React.FC<IInvoiceWidgetProps> = ({ isVisible, setVisible, invoiceId }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: invoice } = useGetInvoice(invoiceId);
  const { data: generatedReportURL, refetch } = useGenerateReport(id);
  return (
    <Drawer
      open={isVisible}
      onClose={() => {
        setVisible(false);
      }}
      anchor="right"
    >
      <button
        className="button button--small button--drawer"
        style={{ position: 'absolute', zIndex: 15 }}
        onClick={() => {
          setVisible(false);
        }}>
        <img src={IconClose} width="32" height="32" alt="close"/>
      </button>
      <div className="preliminary-invoice_container">
        <h2 style={{ marginBottom: '24px' }}>{t('invoice.title')}</h2>
        {invoice?.records?.length > 0 && (
          <div className="preliminary-invoice">
            <h4 style={{ marginBottom: '8px' }}>{t('invoice.service-summary')}</h4>
            <div className="product-items">
              <div className="products_header">
                <div className="products_header-service">{t('invoice.service-item')}</div>
                <div className="products_header-qty">{t('invoice.qty')}</div>
                <div className="products_header-price">{t('invoice.price')}</div>
                <div className="products_header-empty" />
              </div>
              {invoice?.records.map((record) => <div key={record.id} className="product-item">
                <div className="product-item_service">{t(TIME_TYPES[record.service_type])}</div>
                <div className="products-item_-qty">{record.duration}</div>
                <div className="products-item_price">
                  <div className="products-item_price-main">{record.price}€</div>
                </div>
                <div className="products-item_edit-invoice" />
              </div>)}
            </div>
          </div>
        )}
        {invoice?.products?.length > 0 && (
          <div className="preliminary-invoice" style={{ marginTop: '24px', marginBottom: '56px' }}>
            <h4 style={{ marginBottom: '8px' }}>{t('invoice.service-items')}</h4>
            <div className="product-items">
              <div className="products_header">
                <div className="products_header-service">{t('invoice.service-item')}</div>
                <div className="products_header-qty">{t('invoice.qty')}</div>
                <div className="products_header-price">{t('invoice.price')}</div>
                <div className="products_header-empty" />
              </div>
              {invoice?.products.map((product, index) => <Invoice
                isNotEdit
                product={product}
                key={index} />)}
            </div>
          </div>
        )}
        <div className="product-items_total" style={{ marginBottom: '24px' }}>
          <div className="product-items_total-subtotal">
            <span>{t('invoice.subtotal')}</span>
            <span>{invoice?.subtotal}€</span>
          </div>
          <div className="product-items_total-vat">
            <span>{t('invoice.vat')} {invoice?.vat_rate ? invoice?.vat_rate : 0}%</span>
            <span>{invoice?.vat_amount ? invoice?.vat_amount : 0}€</span>
          </div>
          <div className="product-items_total-totalsum">
            <span>{t('invoice.total-sum')}</span>
            <span>{invoice?.total}€</span>
          </div>
        </div>
        <button
          className="button button--text-small"
          style={{ alignSelf: 'end', padding: 0 }}
          onClick={async () => {
            await refetch();
            if (generatedReportURL?.url) {
              window.open(generatedReportURL?.url, '_blank');
            }
          }}
        >
          {t('invoice.show-pdf')}
        </button>
      </div>
    </Drawer>
  );
};

export default InvoiceWidget;
