import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { type AxiosPromise, type AxiosResponse } from 'axios';
import { apiClientAWS } from '@shared/api/base';

interface IUploadInvoiceProcess {
  'Content-Type': string
  'key': string
  'policy': string
  'x-amz-algorithm': string
  'x-amz-credential': string
  'x-amz-date': string
  'x-amz-signature': string
  file: File
  url: string
}

export const useUploadInvoiceProcess = (): UseMutationResult<
  unknown,
  unknown,
  IUploadInvoiceProcess,
  unknown
  > => {
  const uploadInvoiceProcess = async (payload: IUploadInvoiceProcess): AxiosPromise<AxiosResponse> => {
    return await apiClientAWS.post(
      payload.url,
      payload,
    );
  };

  const mutationOptions: {
    mutationFn: (payload: IUploadInvoiceProcess) => AxiosPromise<AxiosResponse>
    onSuccess: (data: AxiosResponse) => void
  } = {
    mutationFn: uploadInvoiceProcess,
    onSuccess: () => {},
  };

  return useMutation(mutationOptions);
};
