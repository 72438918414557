const rad = (x: number): number => {
  return x * Math.PI / 180;
};

export const getDistanceLocation = (locA: Record<string, number>, locB: Record<string, number>): string => {
  const R = 6378137;
  const dLat = rad(locB.lat - locA.lat);
  const dLong = rad(locB.lng - locB.lng);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(locA.lat)) * Math.cos(rad(locB.lat)) *
        Math.sin(dLong / 2) * Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return (R * c / 1000).toFixed(1);
};
