import React, { useState } from 'react';

import resetIcon from '@shared/assets/Icons/icon_reset.svg';
import { Box, Button, FormControl, Icon, MenuItem, Select, TextField } from '@mui/material';
import { ClientsListTable } from '@features/index';

import classNames from 'classnames/bind';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const ClientsListWidget: React.FC = () => {
  const [selectedStatus, setSelectedStatus] = useState('empty');
  const [searchField, setSearchField] = useState('');

  const ResetIcon = (
    <Icon>
      <img src={resetIcon} alt="Reset filters"/>
    </Icon>
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          maxWidth: '1160px',
          marginBottom: '24px',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: '16px',
          alignItems: 'center',
        }}
      >
        <TextField
          id="outlined-basic"
          placeholder="Search"
          className={cx('search-input')}
          value={searchField}
          onChange={(e) => {
            setSearchField(e.target.value);
          }}
          sx={{
            width: '50%',
            maxWidth: '622px',
            backgroundColor: 'var(--white)',
            backgroundPosition: '12px center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '20px auto',
            border: 'none',
            '@media (max-width: 1100px)': {
              maxWidth: '39vw',
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '1px var(--gray-middle) solid !important',
              },
              '& ::placeholder': {
                color: 'var(--placeholder)',
                opacity: '1',
                transition: 'all 0.25s linear',
              },
              '& input': {
                border: 'none',
              },
            },
          }}
          InputProps={{
            style: {
              fontFamily: 'var(--font-main)',
              fontSize: '1.4rem',
              fontWeight: '400',
              color: 'var(--text-color)',
              borderRadius: '8px',
              paddingLeft: '40px',
              height: '48px',
              padding: '0 12px 0 30px',
              transition: 'all 0.25s linear',
            },
          }}
        />

        {/* <FormControl */}
        {/*  sx={{ */}
        {/*    minWidth: 142, */}
        {/*    '& .MuiOutlinedInput-root': { */}
        {/*      '& fieldset': { */}
        {/*        borderColor: 'var(--gray-middle)', */}
        {/*        borderRadius: '8px', */}
        {/*        borderWidth: '1px !important', */}
        {/*        transition: '0.3s', */}
        {/*      }, */}
        {/*      '&:hover': { */}
        {/*        '& fieldset': { */}
        {/*          borderColor: 'var(--accent-hover)', */}
        {/*        }, */}
        {/*      }, */}
        {/*    }, */}
        {/*  }} */}
        {/* > */}
        {/*  <Select */}
        {/*    className={cx('select-wrapper')} */}
        {/*    IconComponent={() => null} */}
        {/*    labelId="client-status-label" */}
        {/*    id="client-status-label-select" */}
        {/*    label="" */}
        {/*    value={selectedStatus} */}
        {/*    onChange={(e) => { */}
        {/*      const selectedValue = e.target.value; */}
        {/*      setSelectedStatus(selectedValue); */}
        {/*    }} */}
        {/*  > */}
        {/*    <MenuItem value="empty" className={cx('select-item')}>Client Status</MenuItem > */}
        {/*    <MenuItem value="VIP" className={cx('select-item')}>VIP</MenuItem > */}
        {/*    <MenuItem value="New" className={cx('select-item')}>New</MenuItem > */}
        {/*    <MenuItem value="Regular" className={cx('select-item')}>Regular</MenuItem > */}
        {/*  </Select> */}
        {/* </FormControl> */}
        {/* <Button */}
        {/*  variant="text" */}
        {/*  onClick={() => { */}
        {/*    setSearchField(''); */}
        {/*    setSelectedStatus('empty'); */}
        {/*  }} */}
        {/*  startIcon={ResetIcon} */}
        {/*  className={cx('button--text-small')} */}
        {/*  disableRipple */}
        {/* > */}
        {/*  Reset filters */}
        {/* </Button> */}
        {/*<AddNewClient />*/}
      </Box>
      <ClientsListTable />
    </>
  );
};

export default ClientsListWidget;
