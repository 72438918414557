// @ts-nocheck
import React, { type MutableRefObject, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { HeaderNavItems } from '@widgets/HeaderWidget/config/constants';

import Logo from '@shared/assets/Images/logo_white.svg';
import { NavLink } from 'react-router-dom';
import LogOut from '@features/LogOut';
import useOutsideClick from '@shared/lib/hooks/useOutsideClick';
import { ROUTES } from '@shared/config/routes/constants';

import { useServiceProviderProfile } from '@widgets/HeaderWidget/model/useServiceProviderProfile';

import { CreateServiceRequestWidget } from '../../../widgets';

const HeaderWidget: React.FC = () => {
  const { t } = useTranslation();
  const [isOpenAddMenu, setOpenAddMenu] = useState<boolean>(false);
  const [isOpenProfileMenu, setOpenProfileMenu] = useState<boolean>(false);

  const menuRef = useRef(null) as MutableRefObject<HTMLDivElement | null>;
  const profileRef = useRef(null) as MutableRefObject<HTMLDivElement | null>;

  const { data } = useServiceProviderProfile();

  useOutsideClick(menuRef, () => { setOpenAddMenu(false); });
  useOutsideClick(profileRef, () => setTimeout(() => { setOpenProfileMenu(false); }, 150));

  return (
    <header className="header">
      <div className="header__logo">
        <NavLink to={ROUTES.MAIN}><img src={Logo} width="62" height="38" alt="@YourService" /></NavLink>
      </div>
      <div className="header__center container">
        <nav className="navigation">
          {HeaderNavItems.map((nav, index) => (
            <NavLink to={nav.path} key={`${index}-${nav.path}`}>{t(nav.title)}</NavLink>
          ))}
        </nav>

        <div className={
          `dropdown-container
          dropdown-container--dark
          dropdown-container--add
          ${isOpenAddMenu ? 'dropdown-open' : ''}`
        }>
          <div className="dropdown-toggle"
            onClick={() => { setOpenAddMenu(prevState => !prevState); }}
            ref={menuRef}>
            {t('header.add-new')}
          </div>
          <div className={`${isOpenAddMenu ? 'dropdown-active' : ''} dropdown-menu`}>
            <ul>
              <li>
                <CreateServiceRequestWidget fromHeader />
              </li>
              {/* <li><a href="#" className="js-drawer-toggle--client">Client</a></li> */}
              {/* <li><a href="#" className="js-drawer-toggle--technician">Technician</a></li> */}
            </ul>
          </div>
        </div>

      </div>
      <div className="header__auth">
        <div className={
          `dropdown-container
          dropdown-container--light
          dropdown-container--auth
          ${isOpenProfileMenu ? 'dropdown-open' : ''}`
        }>
          <div className="dropdown-toggle"
            onClick={() => { setOpenProfileMenu(prevState => !prevState); }}
            ref={profileRef}>
            <div className="header__auth__avatar">
              {data?.image
                ? <img src={data?.image} width="100%" height="100%" alt="" />
                : data?.company_name[0].toUpperCase()}
            </div>
          </div>
          <div className={`${isOpenProfileMenu ? 'dropdown-active' : ''} dropdown-menu`}>
            <ul>
              <li><a href="#">{data?.company_name}</a></li>
              <li>
                <LogOut />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderWidget;
