// @ts-nocheck
import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { apiClient } from '@shared/api/base';
import { type AxiosPromise, type AxiosResponse } from 'axios';
import { type IAppointment } from '@widgets/MakeAppointmentWidget/config/interfaces';

export const useFetchAppointment = (id: string): UseQueryResult<IAppointment, unknown> => {
  const getAppointment = async (): AxiosPromise<AxiosResponse> => {
    const response = await apiClient.get(`provider/appointments/${id}/`, {
      withCredentials: true,
    });
    return response.data;
  };

  return useQuery(
    {
      queryKey: ['appointment', id],
      queryFn: getAppointment,
      enabled: !!id,
      refetchOnMount: true,
      refetchIntervalInBackground: 10000,
    });
};
