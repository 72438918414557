export const REQUEST_STATUS = {
  NEW: {
    title: 'Request',
    style: 'request',
  },
  IN_PROGRESS: {
    title: 'In progress',
    style: 'inprogress',
  },
  COMPLETED: {
    title: 'Completed',
    style: 'completed',
  },
  PAID: {
    title: 'Paid',
    style: 'paid',
  },
  CANCELLED: {
    title: 'Canceled',
    style: 'canceled',
  },
};

export const REQUEST_CATEGORY = {
  OTHER: 'Other',
  REPAIR: 'Repair',
  PROJECT: 'Project',
  MAINTENANCE: 'Maintenance',
};
