// @ts-nocheck
import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { Drawer } from '@mui/material';
import IconClose from '@shared/assets/Icons/icon_close.svg';
import Invoice from '@widgets/MakeAppointmentWidget/ui/Invoice';
import { type IProduct } from '@widgets/MakeAppointmentWidget/config/interfaces';
import { useServiceProviderProfile } from '@widgets/HeaderWidget/model/useServiceProviderProfile';

interface IPreliminaryInvoiceWidgetProps {
  isVisible: boolean
  setVisible: (boolean) => void
  products: IProduct[]
}

const PreliminaryInvoiceWidget: React.FC<IPreliminaryInvoiceWidgetProps> = ({ isVisible, setVisible, products }) => {
  const { t } = useTranslation();
  const { data: profile } = useServiceProviderProfile();
  const vatPercentage = profile?.vat_rate ? profile?.vat_rate : 0;
  const subTotal = products?.length
    ? products?.reduce((accumulator, item) => {
      const quantity = item.quantity;
      const unitPrice = parseFloat(item.unit_price);
      return accumulator + (quantity * unitPrice);
    }, 0)
    : 0;
  const vatSum = vatPercentage * subTotal / 100;
  return (
    <Drawer
      open={isVisible}
      onClose={() => {
        setVisible(false);
      }}
      anchor="right"
    >
      <button
        className="button button--small button--drawer"
        style={{ position: 'absolute', zIndex: 15 }}
        onClick={() => {
          setVisible(false);
        }}>
        <img src={IconClose} width="32" height="32" alt="close"/>
      </button>
      <div className="preliminary-invoice_container">
        <h2 style={{ marginBottom: '24px' }}>{t('invoice.title-preliminary')}</h2>
        {products?.length > 0 && (
          <div className="preliminary-invoice">
            <h4 style={{ marginBottom: '8px' }}>{t('invoice.service-items')}</h4>
            <div className="product-items">
              <div className="products_header">
                <div className="products_header-service">{t('invoice.service-item')}</div>
                <div className="products_header-qty">{t('invoice.qty')}</div>
                <div className="products_header-price">{t('invoice.price')}</div>
                <div className="products_header-empty" />
              </div>
              {products.map((product, index) => <Invoice
                isNotEdit
                product={product}
                key={index} />)}
            </div>
            <div className="product-items_total">
              <div className="product-items_total-subtotal">
                <span>{t('invoice.subtotal')}</span>
                <span>{subTotal}€</span>
              </div>
              <div className="product-items_total-vat">
                <span>{t('invoice.vat')} {vatPercentage.toString()}%</span>
                <span>{vatSum}€</span>
              </div>
              <div className="product-items_total-totalsum">
                <span>{t('invoice.total-sum')}</span>
                <span>{(subTotal + vatSum).toFixed(2)}€</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default PreliminaryInvoiceWidget;
