import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { type AxiosPromise, type AxiosResponse } from 'axios';
import { apiClient } from '@shared/api/base';
import { localStorageGetItem } from '@shared/lib/utils/localStorage';

import { type IPhoto } from '@widgets/CreateServiceRequestWidget/config/interfaces';

import { STORAGE_KEYS } from '../config/constants';

export const useDeleteUploadPhoto = ({ id, photos, setPhotos }:
  { id: string
    photos: IPhoto[]
    setPhotos: (value: IPhoto[]) => void
  }): UseMutationResult<
    AxiosResponse<AxiosResponse>,
    unknown,
    string,
    unknown
    > => {
  const deleteUploadPhoto = async (id: string): AxiosPromise<AxiosResponse> => {
    return await apiClient.delete(
      `provider/photos/${id}/`,
      {
        withCredentials: true,
        headers: {
          Authorization: `Token ${localStorageGetItem(STORAGE_KEYS.TOKEN)}`,
        },
      },
    );
  };

  const mutationOptions: {
    mutationFn: (id: string) => AxiosPromise<AxiosResponse>
    onSuccess: (data: unknown) => void
    onError: (error: unknown) => void
  } = {
    mutationFn: deleteUploadPhoto,
    onSuccess: () => {
      const updatePhotos = photos.filter(item => item.name !== id);
      setPhotos(updatePhotos);
    },
    onError: () => {},
  };

  return useMutation(mutationOptions);
};
