// @ts-nocheck
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import './styles.scss';
import { ROUTES } from '@shared/config/routes/constants';
import IconBack from '@shared/assets/Icons/icon_back.svg';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ManualInvoice from '@widgets/DetailServiceRequestWidget/ui/ManualInvoice';
import { useUploadInvoice } from '@widgets/DetailServiceRequestWidget/model/useUploadInvoice';
import { useUploadInvoiceProcess } from '@widgets/DetailServiceRequestWidget/model/useUploadInvoiceProcess';
import { useServiceRequest } from '@widgets/DetailServiceRequestWidget/model/useServiceRequest';
import { useUpdateInvoice } from '@widgets/CreateInvoiceWidget/model/useUpdateInvoice';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const PDFuploader = ({ document, setDocument, setVisibleSuccess }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [numPages, setNumPages] = useState<number>();
  const [key, setKey] = useState('');
  const [isVisibleManualInvoice, setVisibleManualInvoice] = useState(false);

  const onSuccessUpdateInvoice = () => {
    setVisibleManualInvoice(false);
  };

  const LoadErrorMessage = () => {
    return (
      <div className="error-message-container">
        <h2>{t('invoice.upload-error-title')}</h2>
        <p>{t('invoice.upload-error-info')}</p>
        <button className="button button--default">{t('invoice.upload-error-again')}</button>
      </div>
    );
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const { data: serviceRequestData } = useServiceRequest(id as string);
  const { mutateAsync: updateInvoice } = useUpdateInvoice(serviceRequestData?.invoice_id, onSuccessUpdateInvoice);
  const { mutate: uploadInvoiceProcess } = useUploadInvoiceProcess({ invoiceId: serviceRequestData?.invoice_id, finishFunction: updateInvoice, key });
  const { mutateAsync: uploadInvoice } = useUploadInvoice({ processFunction: uploadInvoiceProcess, file: document, setKeyFunction: setKey });

  return (
    <>
      <div>
        <div className="pdf-view-navigation">
          <Button
            variant="text"
            disableRipple
            sx={{
              font: '600 1.6rem/1.6 var(--font-main)',
              padding: '0 8px',
              gap: '4px',
              boxShadow: 'none !important',
              backgroundColor: 'transparent',
              height: '32px',
              color: 'var(--accent)',
              display: 'flex',
              alignItems: 'center',
              transition: 'all 0.25s linear',
              '&:hover': {
                color: 'var(--accent-hover)',
                backgroundColor: 'transparent',
              },
              '&:active': {
                color: 'var(--accent-active)',
                backgroundColor: 'transparent',
              },
            }}
            onClick={() => {
              navigate(ROUTES.DETAIL_SERVICE_REQUEST.replace(':id', id as string));
              setDocument(null);
            }}
          >
            <img src={IconBack} width="24" height="24" alt="Back"/>
            {t('invoice.back-to-request')}
          </Button>
          <button
            className="button button--default"
            onClick={() => {
              uploadInvoice({ file_name: document.name, invoice_id: serviceRequestData?.invoice_id });
              setVisibleManualInvoice(true);
            }}
          >
            {t('invoice.continue')}
          </button>
        </div>
        {document && (
          <div className="pdf-view-container">
            <Document
              file={URL.createObjectURL(document)}
              onLoadSuccess={onDocumentLoadSuccess}
              className="pdf-view-document"
              error={<LoadErrorMessage />}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} size={'A4'}/>
              ))}
            </Document>
          </div>
        )}
      </div>
      <ManualInvoice
        isVisible={isVisibleManualInvoice}
        setVisible={setVisibleManualInvoice}
        documentKey={key}
        setDocument={setDocument}
        setVisibleSuccess={setVisibleSuccess}
      />
    </>
  );
};

export default PDFuploader;
