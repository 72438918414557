import { type IPaginationItem } from './interfaces';

export const PAGINATION_ITEMS_LIST: IPaginationItem[] = [
  {
    id: '1',
    value: 20,
    label: 20,
  },
  {
    id: '2',
    value: 30,
    label: 30,
  },
  {
    id: '3',
    value: 40,
    label: 40,
  },
  {
    id: '4',
    value: 50,
    label: 50,
  },
];
