// @ts-nocheck
import React, { useState } from 'react';
import './styles.scss';
import Arrow from '@shared/assets/Icons/icon_arrow_white.svg';
import CloseSlider from '@shared/assets/Icons/icon_gallery_close.svg';
import { type IPhoto } from '@widgets/CreateServiceRequestWidget/config/interfaces';
import closeIcon from '@shared/assets/Icons/icon_close.svg';

interface IPhotoDetail {
  file_size: number
  height: number
  width: number
  id: string
  media_type: number
  url: string
}

interface ISliderProps {
  images: IPhotoDetail[] | IPhoto[] | undefined
  createServiceRequest?: boolean
  setPhotoId?: (string) => void
  handleRemoveImage?: (string) => void
}

const SliderPhoto: React.FC<ISliderProps> = ({ images, setPhotoId, handleRemoveImage, createServiceRequest }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [showOverlay, setShowOverlay] = useState(false);

  const nextImage = (): void => {
    setCurrentImage(currentImage === images?.length - 1 ? 0 : currentImage + 1);
  };

  const prevImage = (): void => {
    setCurrentImage(currentImage === 0 ? images?.length - 1 : currentImage - 1);
  };

  const onClickSliderImage = (index: number): void => {
    setShowOverlay(true);
    setCurrentImage(index);
  };

  const onClickCloseImage = (): void => {
    setShowOverlay(false);
    setCurrentImage(0);
  };

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    if (e.currentTarget === e.target) {
      setShowOverlay(false);
      setCurrentImage(0);
    }
  };

  return (
    <>
      {createServiceRequest
        ? images?.map((photo, index) => (
          <div key={index} className="files-set__item">
            <img className={currentImage === index ? 'active' : ''}
              src={photo.href}
              onClick={() => { onClickSliderImage(index); }}
              alt={photo.name}
              width="100%"
              height="100%"/>
            <button
              className="button button--small button--delete"
              onClick={(e) => {
                e.preventDefault();
                if (setPhotoId && handleRemoveImage) {
                  setPhotoId(photo.name);
                  handleRemoveImage(photo.name);
                }
              }}>
              <img src={closeIcon} width="20" height="20" alt="delete"/>
            </button>
          </div>
        ))
        : images?.map((image, index) => {
          return (
            <div className="request__photos__item" key={index}>
              <img
                src={image.url}
                alt={`image-${index}`}
                onClick={() => { onClickSliderImage(index); }}
                className={currentImage === index ? 'active' : ''}
              />
            </div>
          );
        },
        )
      }

      {showOverlay && !!images?.length && (
        <div className="overlay" onClick={handleOverlayClick}>
          {images.length > 1 &&
              (<img className="overlay__arrow-prev" src={Arrow} onClick={prevImage} alt="previous" />)
          }
          <img className="full-image"
            src={createServiceRequest ? images[currentImage].href : images[currentImage].url}
            alt={`image-${currentImage}`} height="80%"
          />
          {images.length > 1 &&
              (<img className="overlay__arrow-next" src={Arrow} onClick={nextImage} alt="next" />)
          }
          <img className="overlay__close" src={CloseSlider} onClick={onClickCloseImage} alt="close" />
        </div>
      )}
    </>
  );
};

export default SliderPhoto;
