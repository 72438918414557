import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { apiClient } from '@shared/api/base';
import { type AxiosPromise, type AxiosResponse } from 'axios';
import { type IReport } from '@widgets/DetailServiceRequestWidget/config/interfaces';

export const useReport = (id: string):
  UseQueryResult<IReport, unknown> => {
  const getReport = async (id: string): AxiosPromise<IReport> => {
    const response = await apiClient.get(`provider/reports/${id}/`, {
      withCredentials: true,
    });
    return response.data;
  };

  const queryOptions = {
    queryKey: ['report', id],
    queryFn: async () => await getReport(id),
    onSuccess: (data: AxiosResponse<IReport>) => {
      // eslint-disable-next-line no-console
      console.log(data);
    },
    onError: (error: unknown) => {
      // eslint-disable-next-line no-console
      console.error(error);
    },
    enabled: !!id,
  };

  return useQuery(queryOptions);
};
