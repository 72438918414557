import { type MutateFunction, useMutation, type UseMutationResult } from '@tanstack/react-query';
import { type AxiosPromise, type AxiosResponse } from 'axios';
import { apiClient } from '@shared/api/base';

import { type IUploadMediaProcess } from '@widgets/CreateServiceRequestWidget/config/interfaces';

interface IUploadInvoice {
  file_name: string
  invoice_id: string
}

export const useUploadInvoice = ({
  processFunction,
  file,
  setKeyFunction,
}: { processFunction: (...args: Parameters<MutateFunction<unknown, unknown, IUploadMediaProcess, unknown>>) => void
  file: File | null
  setKeyFunction: (value: (((prevState: string) => string) | string)) => void }): UseMutationResult<
  unknown,
  unknown,
  IUploadInvoice,
  unknown
  > => {
  const uploadInvoice = async (payload: IUploadInvoice): AxiosPromise<AxiosResponse> => {
    return await apiClient.post(
      'provider/upload/invoice/',
      payload,
    );
  };

  const mutationOptions: {
    mutationFn: (payload: IUploadInvoice) => AxiosPromise<AxiosResponse>
    onSuccess: (data: AxiosResponse) => void
    onError: (error: unknown) => void
  } = {
    mutationFn: uploadInvoice,
    onSuccess: (data: AxiosResponse) => {
      setKeyFunction((data.data.fields.key) as string);
      const processRequest = {
        ...data.data.fields,
        file,
        url: data.data.url,
      } as IUploadMediaProcess;
      processFunction(processRequest);
    },
    onError: () => {},
  };

  return useMutation(mutationOptions);
};
