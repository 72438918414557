// @ts-nocheck
import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { apiClient } from '@shared/api/base';
import { queryClient } from '@shared/api/query-client';
import { type AxiosPromise, type AxiosResponse } from 'axios';

export const useTouchServiceRequest = (id: string): UseMutationResult<unknown, unknown, unknown, unknown> => {
  const touchServiceRequest = async (): AxiosPromise<AxiosResponse> => {
    return await apiClient.post(
      `provider/service-requests/${id}/touch/`,
    );
  };

  const mutationOptions: {
    onError: (error: unknown) => void
    mutationFn: (payload: string) => AxiosPromise<AxiosResponse>
    onSuccess: (data: unknown) => void
  } = {
    mutationFn: touchServiceRequest,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['service-request', id] });
      queryClient.setQueryData(['service-request', id], (detailServiceRequest) => ({
        ...detailServiceRequest,
        mark: false,
      }));
    },
    onError: () => {
    },
  };

  return useMutation(mutationOptions);
};
