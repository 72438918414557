import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import {
  type ISignInConfirmRequest,
  type ISignInConfirmResponse,
} from '@pages/AuthPage/config/interfaces';
import { apiClient } from '@shared/api/base';
import { type AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@shared/config/routes/constants';
import { localStorageSetItem } from '@shared/lib/utils/localStorage';
import { STORAGE_KEYS } from '@pages/AuthPage/config/constants';

export const useSignInConfirm = (): UseMutationResult<
  AxiosResponse<ISignInConfirmResponse>,
  unknown,
  ISignInConfirmRequest,
  unknown> => {
  const navigate = useNavigate();

  const signInConfirm = async (payload: ISignInConfirmRequest):
    Promise<AxiosResponse<ISignInConfirmResponse>> => {
    return await apiClient.post(
      'provider/auth/sign-in-confirm/',
      payload,
    );
  };

  const mutationOptions = {
    onError: (error: unknown) => {
      // eslint-disable-next-line no-console
      console.error(error);
    },
    mutationFn: signInConfirm,
    onSuccess: (data: AxiosResponse<ISignInConfirmResponse>) => {
      localStorageSetItem(STORAGE_KEYS.TOKEN, data.data.token);
      navigate(ROUTES.MAIN);
    },
  };

  return useMutation(mutationOptions);
};
