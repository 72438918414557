import type { TypographyOptions } from '@mui/material/styles/createTypography';

export const typography: TypographyOptions = {
  fontFamily: 'Public Sans',
  subtitle1: {
    fontWeight: 'bold',
  },
  subtitle2: {
    fontSize: '1rem',
    lineHeight: '1.5',
    fontWeight: '400',
  },
  h1: {
    fontSize: '3rem',
    fontWeight: 'bold',
  },
  h2: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  h3: {
    fontSize: '1.5rem',
  },
  h4: {
    fontSize: '1.25rem',
    textTransform: 'none',
  },
  h5: {
    fontSize: '1.125rem',
  },
  button: {
    textTransform: 'none',
  },
};
