import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { type AxiosPromise, type AxiosResponse } from 'axios';
import { apiClient } from '@shared/api/base';
import { localStorageGetItem } from '@shared/lib/utils/localStorage';

import { type IPhoto } from '@widgets/CreateServiceRequestWidget/config/interfaces';

import { STORAGE_KEYS } from '../config/constants';

interface IUploadPhotoFinish {
  key: string
  width: number
  height: number
  file_size: number
  service_request_id: string
}

export const useUploadPhotoFinish = (
  { photos, setPhotos }: { photos: IPhoto[], setPhotos: (photos: IPhoto[]) => void },
): UseMutationResult<
  unknown,
  unknown,
  IUploadPhotoFinish,
  unknown
  > => {
  const uploadPhotoFinish = async (payload: IUploadPhotoFinish): AxiosPromise<AxiosResponse> => {
    return await apiClient.post(
      'provider/photos/',
      payload, {
        headers: {
          Authorization: `Token ${localStorageGetItem(STORAGE_KEYS.TOKEN)}`,
        },
      },
    );
  };

  const mutationOptions: {
    mutationFn: (payload: IUploadPhotoFinish) => AxiosPromise<AxiosResponse>
    onSuccess: (data: AxiosResponse) => void
    onError: (error: unknown) => void
  } = {
    mutationFn: uploadPhotoFinish,
    onSuccess: (data: AxiosResponse) => {
      const newPhoto = {
        name: data.data.id,
        href: data.data.url,
      };
      setPhotos([...photos, newPhoto]);
    },
    onError: () => {},
  };

  return useMutation(mutationOptions);
};
