import mainEN from '@shared/assets/locales/en/main.json';
import mainDE from '@shared/assets/locales/de/main.json';
import mainFR from '@shared/assets/locales/fr/main.json';
import mainPT from '@shared/assets/locales/pt/main.json';

export const defaultNS = 'main';
export const resources = {
  en: {
    main: mainEN,
  },
  de: {
    main: mainDE,
  },
  fr: {
    main: mainFR,
  },
  pt: {
    main: mainPT,
  },
};
