// @ts-nocheck
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../../styles.scss';
import { Drawer, FormControl, MenuItem, Select } from '@mui/material';
import IconClose from '@shared/assets/Icons/icon_close.svg';
import { useParams } from 'react-router-dom';
import { UNITS } from '@widgets/MakeAppointmentWidget/config/constants';
import { useAddProduct } from '@widgets/MakeAppointmentWidget/model/useAddProduct';
import { Controller, useForm } from 'react-hook-form';
import { type IProduct } from '@widgets/MakeAppointmentWidget/config/interfaces';
import { useUpdateProduct } from '@widgets/MakeAppointmentWidget/model/useUpdateProduct';
import { useServiceRequest } from '@widgets/DetailServiceRequestWidget/model/useServiceRequest';
import { useFetchProducts } from '@widgets/MakeAppointmentWidget/model/useFetchProducts';
import { useUpdateInvoice } from '@widgets/CreateInvoiceWidget/model/useUpdateInvoice';
import { useServiceProviderProfile } from '@widgets/HeaderWidget/model/useServiceProviderProfile';
import {TIME_TYPES} from "@shared/config/common/constants";

interface IAddInvoiceProps {
  profileId?: string
  isVisible: boolean
  setVisible: (boolean) => void
  setLocalProducts?: () => void
  product?: IProduct
  setCurrentProduct?: (boolean) => void
  todo?: unknown
  setTodo?: (boolean) => void
  isTodoEdit?: boolean
}

const AddInvoice: React.FC<IAddInvoiceProps> = ({ isVisible, setVisible, product, setCurrentProduct, setLocalProducts, isTodoEdit, todo, setTodo }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: serviceRequestDetail } = useServiceRequest(id as string);
  const { data: profile } = useServiceProviderProfile();

  const { control, handleSubmit, register, watch, reset, setValue, formState: { errors, isValid } } = useForm({
    defaultValues: {
      service_request_id: id,
      name: '',
      quantity: 1,
      unit: 'PIECES',
      unit_price: 0,
      vat_percentage: profile?.vat_rate,
    },
  });

  useEffect(() => {
    if (product) {
      setValue('name', product.name);
      setValue('quantity', product.quantity);
      setValue('unit', product.unit);
      setValue('unit_price', product.unit_price);
      setValue('vat_percentage', product.vat_percentage);
    }
  }, [product, setValue]);

  useEffect(() => {
    if (todo) {
      setValue('name', t(TIME_TYPES[todo.service_type]));
      setValue('quantity', todo.duration);
      setValue('unit', 'HOURS');
      setValue('unit_price', todo.price);
    }
  }, [todo, setValue]);

  const formValues = watch();

  const quantity = formValues.quantity;
  const unitPrice = formValues.unit_price;

  const vatSum = unitPrice * profile?.vat_rate / 100;

  const onSuccessUpdateInvoice = () => {
    setVisible(false);
    setTodo(null);
  };

  const { mutateAsync: addProduct } = useAddProduct(id, reset, setVisible, serviceRequestDetail?.invoice_id);
  const { mutateAsync: updateProduct } = useUpdateProduct(product?.id, reset, setVisible, serviceRequestDetail?.invoice_id);
  const { mutateAsync: updateInvoice } = useUpdateInvoice(serviceRequestDetail?.invoice_id, onSuccessUpdateInvoice);
  const { refetch } = useFetchProducts(id as string);

  const onSubmit = async data => {
    if (serviceRequestDetail.appointment || serviceRequestDetail?.todos.every(todo => todo.status === 'COMPLETED')) {
      if (todo) {
        const updatedRecord = {
          service_request_id: id,
          records: [
            {
              id: todo.id,
              price: data.unit_price,
            },
          ],
        };
        await updateInvoice(updatedRecord);
      }
      if (product) {
        await updateProduct(data);
        await refetch();
      }
      if (!product && !todo) {
        await addProduct(data);
      }
    } else {
      if (product?.isLocal) {
        setLocalProducts((prevState) => {
          const newProducts = prevState;
          for (let i = 0; i < newProducts.length; i++) {
            if (newProducts[i].id === product?.id) {
              newProducts[i].name = data.name;
              newProducts[i].quantity = data.quantity;
              newProducts[i].unit = data.unit;
              newProducts[i].unit_price = data.unit_price;
            }
          }
          return prevState;
        });
        setVisible(false);
        reset();
      } else {
        data.id = Date.now();
        data.isLocal = true;
        setLocalProducts((prevState) => [...prevState, data]);
        setVisible(false);
        reset();
      }
    }
  };

  return (
    <Drawer
      open={isVisible}
      onClose={() => {
        setVisible(false);
        setTodo(null);
        setCurrentProduct(null);
        reset();
      }}
      anchor="bottom"
      PaperProps={{
        sx: {
          width: '614px',
          height: '563px',
          position: 'absolute',
          left: 'auto',
          right: 0,
          bottom: 0,
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
        },
      }}
    >
      <button
        className="button button--small button--drawer"
        style={{ position: 'absolute', zIndex: 20 }}
        onClick={() => {
          setVisible(false);
          setTodo(null);
          setCurrentProduct(null);
          reset();
        }}>
        <img src={IconClose} width="32" height="32" alt="close"/>
      </button>
      <form className="add-invoice_form" onSubmit={handleSubmit(onSubmit)}>
        <div
          className="drawer__inner"
          style={{
            position: 'relative',
            width: '614px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}>
          <div>
            <h2 style={{ marginBottom: '24px' }}>{t('makeAppointment.service-item')}</h2>
            <div className="form__row__group" style={{ marginBottom: '24px' }}>
              <div className="form__row">
                <div className="form__label">
                  {t('makeAppointment.service-item')}<span className="required" style={{ color: '#e03137' }}>*</span>
                </div>
                <input
                  type="text"
                  disabled={!!todo}
                  name="service-item"
                  placeholder={t('makeAppointment.service-item')}
                  {...register('name', { required: 'Service/Item is required' })}
                />
              </div>
            </div>
            <div className="form__row__group">
              <div className="form__row">
                <div className="form__label">
                  {t('makeAppointment.quantity')}<span className="required" style={{ color: '#e03137' }}>*</span>
                </div>
                <input type={`${todo ? 'text' : 'number'}`}
                  placeholder="0"
                  disabled={!!todo}
                  {...register('quantity', { required: 'Quantity is required' })}
                />
              </div>
              <div className="form__row">
                <div className="form__label">{t('makeAppointment.units')}<span className="required" style={{ color: '#e03137' }}>*</span></div>
                <FormControl
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'var(--gray-middle)',
                        borderRadius: '8px',
                        borderWidth: '1px !important',
                        transition: '0.3s',
                      },
                      '&:hover': {
                        '& fieldset': {
                          borderColor: 'var(--gray-middle)',
                        },
                      },
                      '&.Mui-disabled': {
                        '& fieldset': {
                          borderColor: 'var(--gray-middle)',
                        },
                        backgroundColor: 'var(--disabled-bg)',
                      },
                    },
                  }}
                >
                  <Controller
                    name="unit"
                    control={control}
                    rules={{ required: 'Unit is required' }}
                    render={({ field: controllerField }) => (
                      <Select
                        className="select-wrapper"
                        IconComponent={() => null}
                        labelId="client-status-label"
                        id="client-status-label-select"
                        disabled={!!todo}
                        label=""
                        {...controllerField}
                      >
                        {
                          UNITS.map(unit => (
                            <MenuItem key={unit.id} value={unit.value} className="select-item">
                              {t(unit.label)}
                            </MenuItem>
                          ))
                        }
                      </Select>
                    )}
                  />
                </FormControl>
              </div>
              <div className="form__row">
                <div className="form__label">
                  {t('makeAppointment.price-per-unit')}<span className="required" style={{ color: '#e03137' }}>*</span>
                </div>
                <input type="number"
                  name="service-item-title"
                  placeholder="Price per units"
                  {...register('unit_price', { required: 'Price per units is required' })}
                />
              </div>
            </div>
          </div>

          <div className="table table--total-sum" style={{ marginBottom: '48px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '12px' }}>
              <div className="text-gray-light">{t('invoice.vat')} {profile?.vat_rate ? profile?.vat_rate : 0}%</div>
              <div className="text-gray-light">{todo ? vatSum.toFixed(2) : (quantity * vatSum).toFixed(2)}€</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className="total-sum">{t('invoice.total-sum')}</div>
              <div className="total-sum">
                {todo ? (parseFloat(unitPrice) + vatSum).toFixed(2) : ((quantity * vatSum) + (quantity * unitPrice)).toFixed(2)}€
              </div>
            </div>
          </div>

          <div className="buttons__group">
            <button className="button button--border" type="button" onClick={() => { setVisible(false); }}>
              {t('makeAppointment.cancel')}
            </button>
            <button
              className="button button--default"
              type="submit"
              disabled={!isValid}
            >
              {t('makeAppointment.add')}
            </button>
          </div>
        </div>
      </form>
    </Drawer>
  );
};

export default AddInvoice;
