import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { type AxiosPromise, type AxiosResponse } from 'axios';
import { apiClient } from '@shared/api/base';
import { localStorageGetItem } from '@shared/lib/utils/localStorage';

import { type IVideo } from '@widgets/CreateServiceRequestWidget/config/interfaces';

import { STORAGE_KEYS } from '../config/constants';

interface IUploadVideoFinish {
  key: string
  file_size: number
  width: number
  height: number
  duration: number
  codec: string
  cover: {
    key: string
    file_size: number
    width: number
    height: number
  }
  service_request_id: string
}

export const useUploadVideoFinish = (
  { videos, setVideos, setImageCover }: {
    videos: IVideo[]
    setVideos: (videos: IVideo[]) => void
    setImageCover: (value: (((prevState: string) => string) | string)) => void
  },
): UseMutationResult<
  unknown,
  unknown,
  IUploadVideoFinish,
  unknown
  > => {
  const uploadVideoFinish = async (payload: IUploadVideoFinish): AxiosPromise<AxiosResponse> => {
    return await apiClient.post(
      'provider/videos/',
      payload, {
        headers: {
          Authorization: `Token ${localStorageGetItem(STORAGE_KEYS.TOKEN)}`,
        },
      },
    );
  };

  const mutationOptions: {
    mutationFn: (payload: IUploadVideoFinish) => AxiosPromise<AxiosResponse>
    onSuccess: (data: AxiosResponse) => void
    onError: (error: unknown) => void
  } = {
    mutationFn: uploadVideoFinish,
    onSuccess: (data: AxiosResponse) => {
      setImageCover((data.data.cover.url) as string);
      const newVideo = {
        name: data.data.id,
        href: data.data.url,
      };
      setVideos([...videos, newVideo]);
    },
    onError: () => {},
  };

  return useMutation(mutationOptions);
};
