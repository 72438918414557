import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { resources, defaultNS } from '@shared/config/i18n/resources';

const i18nConfig = {
  interpolation: {
    escapeValue: false,
  },
  defaultNS,
  resources,
  lng: navigator.language.slice(0, 2).toLowerCase() || 'en',
  fallbackLng: 'en',
};

// @ts-ignore
void i18n.use(initReactI18next).init(i18nConfig);

export default i18n;
