import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { apiClient } from '@shared/api/base';
import { type AxiosPromise, type AxiosResponse } from 'axios';
import { type ITechniciansList } from '@features/TechniciansListTable/config/interfaces';

export const useFetchTechnicians = (date?: string,
  startTime?: string,
  endTime?: string): UseQueryResult<ITechniciansList, unknown> => {
  const getTechniciansByTimePeriod = async (date?: string, startTime?: string, endTime?: string): AxiosPromise<AxiosResponse> => {
    const response = await apiClient.get(`provider/technicians/?date=${date}&start_time=${startTime}&end_time=${endTime}`, {
      withCredentials: true,
    });
    return response.data;
  };

  return useQuery(
    {
      queryKey: ['technicians', date, startTime, endTime],
      queryFn: async () => await getTechniciansByTimePeriod(date, startTime, endTime),
      enabled: !!date && !!startTime && !!endTime,
    });
};
