import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { type ISignInRequest } from '@pages/AuthPage/config/interfaces';
import { apiClient } from '@shared/api/base';
import { type AxiosPromise, type AxiosResponse } from 'axios';

export const useSignIn = (): UseMutationResult<unknown, unknown, ISignInRequest, unknown> => {
  const signIn = async (payload: ISignInRequest): AxiosPromise<AxiosResponse> => {
    return await apiClient.post(
      'provider/auth/sign-in/',
      payload,
    );
  };

  const mutationOptions: {
    onError: (error: unknown) => void
    mutationFn: (payload: ISignInRequest) => AxiosPromise<AxiosResponse>
    onSuccess: (data: unknown) => void
  } = {
    mutationFn: signIn,
    onSuccess: () => {
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
    },
  };

  return useMutation(mutationOptions);
};
