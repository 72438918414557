import { type IRequestStatus, type ITypeOfWork } from './interfaces';

export const REQUEST_STATUS_LIST: IRequestStatus[] = [
  {
    id: '0',
    value: 'empty',
    label: 'requestStatus.empty',
  },
  {
    id: '1',
    value: 'CANCELLED',
    label: 'requestStatus.canceled',
  },
  {
    id: '2',
    value: 'PAID',
    label: 'requestStatus.paid',
  },
  {
    id: '3',
    value: 'IN_PROGRESS',
    label: 'requestStatus.progress',
  },
  {
    id: '4',
    value: 'COMPLETED',
    label: 'requestStatus.completed',
  },
  {
    id: '5',
    value: 'NEW',
    label: 'requestStatus.new',
  },
];

export const TYPE_OF_WORK_LIST: ITypeOfWork[] = [
  {
    id: '0',
    value: 'empty',
    label: 'typeOfWork.empty',
  },
  {
    id: '1',
    value: 'MAINTENANCE',
    label: 'typeOfWork.maintenance',
  },
  {
    id: '2',
    value: 'REPAIR',
    label: 'typeOfWork.repair',
  },
  {
    id: '3',
    value: 'PROJECT',
    label: 'typeOfWork.project',
  },
  {
    id: '4',
    value: 'OTHER',
    label: 'typeOfWork.other',
  },
];
