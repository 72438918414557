export const getGeneratedTimeArray = (excludeEarlier = false, startTime = '', endTime = '') => {
  const times = [];
  const currentTime = new Date();
  const currentHour = currentTime.getHours();
  const currentMinutes = currentTime.getMinutes();
  if (excludeEarlier) {
    endTime = '';
  }

  const timeToMinutes = (time: string): number => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };

  let startMinutes = startTime ? timeToMinutes(startTime) : 0;
  let endMinutes = endTime ? timeToMinutes(endTime) : 24 * 60;

  if (startTime) {
    startMinutes += 30;
  }

  if (endTime) {
    endMinutes -= 30;
  }

  for (let hour = 0; hour < 24; hour++) {
    for (let minutes = 0; minutes < 60; minutes += 30) {
      const totalMinutes = hour * 60 + minutes;
      const formattedHour = hour.toString().padStart(2, '0');
      const formattedMinutes = minutes.toString().padStart(2, '0');
      const timeString = `${formattedHour}:${formattedMinutes}`;

      if (excludeEarlier) {
        if (totalMinutes >= currentHour * 60 + currentMinutes) {
          if (totalMinutes >= startMinutes && totalMinutes <= endMinutes) {
            times.push(timeString);
          }
        }
      } else {
        if (totalMinutes >= startMinutes && totalMinutes <= endMinutes) {
          times.push(timeString);
        }
      }
    }
  }

  return times;
};
