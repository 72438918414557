import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

import resetIcon from '@shared/assets/Icons/icon_reset.svg';
import { Box, Button, FormControl, Icon, MenuItem, Select, TextField } from '@mui/material';
import { AddNewClient, TechniciansListTable } from '@features/index';
import classNames from 'classnames/bind';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const TechniciansListWidget: React.FC = () => {
  const { t } = useTranslation();
  const [selectedStatus, setSelectedStatus] = useState('empty');
  const [searchField, setSearchField] = useState('');

  const ResetIcon = (
    <Icon>
      <img src={resetIcon} alt="Reset filters"/>
    </Icon>
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          maxWidth: '1160px',
          marginBottom: '24px',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: '16px',
          alignItems: 'center',
        }}
      >
        <TextField
          id="outlined-basic"
          placeholder="Search"
          className={cx('search-input')}
          value={searchField}
          onChange={(e) => {
            setSearchField(e.target.value);
          }}
          sx={{
            width: '50%',
            maxWidth: '622px',
            backgroundColor: 'var(--white)',
            backgroundPosition: '12px center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '20px auto',
            border: 'none',
            '@media (max-width: 1100px)': {
              maxWidth: '39vw',
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '1px var(--gray-middle) solid !important',
              },
              '& ::placeholder': {
                color: 'var(--placeholder)',
                opacity: '1',
                transition: 'all 0.25s linear',
              },
              '& input': {
                border: 'none',
              },
            },
          }}
          InputProps={{
            style: {
              fontFamily: 'var(--font-main)',
              fontSize: '1.4rem',
              fontWeight: '400',
              color: 'var(--text-color)',
              borderRadius: '8px',
              paddingLeft: '40px',
              height: '48px',
              padding: '0 12px 0 30px',
              transition: 'all 0.25s linear',
            },
          }}
        />

        {/*<FormControl*/}
        {/*  sx={{*/}
        {/*    minWidth: 142,*/}
        {/*    '& .MuiOutlinedInput-root': {*/}
        {/*      '& fieldset': {*/}
        {/*        borderColor: 'var(--gray-middle)',*/}
        {/*        borderRadius: '8px',*/}
        {/*        borderWidth: '1px !important',*/}
        {/*        transition: '0.3s',*/}
        {/*      },*/}
        {/*      '&:hover': {*/}
        {/*        '& fieldset': {*/}
        {/*          borderColor: 'var(--accent-hover)',*/}
        {/*        },*/}
        {/*      },*/}
        {/*    },*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <Select*/}
        {/*    className={cx('select-wrapper')}*/}
        {/*    IconComponent={() => null}*/}
        {/*    labelId="client-status-label"*/}
        {/*    id="client-status-label-select"*/}
        {/*    label=""*/}
        {/*    value={selectedStatus}*/}
        {/*    onChange={(e) => {*/}
        {/*      const selectedValue = e.target.value;*/}
        {/*      setSelectedStatus(selectedValue);*/}
        {/*    }}*/}
        {/*    sx={{*/}
        {/*      minWidth: '170px',*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <MenuItem value="empty" className={cx('select-item')}>{t('technicianStatus.empty')}</MenuItem >*/}
        {/*    <MenuItem value="Busy" className={cx('select-item')}>{t('technicianStatus.busy')}</MenuItem >*/}
        {/*    <MenuItem value="Available" className={cx('select-item')}>{t('technicianStatus.available')}</MenuItem >*/}
        {/*    <MenuItem value="Unavailable" className={cx('select-item')}>{t('technicianStatus.unavailable')}</MenuItem >*/}
        {/*  </Select>*/}
        {/*</FormControl>*/}
        {/*<Button*/}
        {/*  variant="text"*/}
        {/*  onClick={() => {*/}
        {/*    setSearchField('');*/}
        {/*    setSelectedStatus('empty');*/}
        {/*  }}*/}
        {/*  startIcon={ResetIcon}*/}
        {/*  className={cx('button--text-small')}*/}
        {/*  disableRipple*/}
        {/*>*/}
        {/*  Reset filters*/}
        {/*</Button>*/}
        {/*<AddNewClient />*/}
      </Box>
      <TechniciansListTable />
    </>
  );
};

export default TechniciansListWidget;
