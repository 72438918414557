// @ts-nocheck
import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { Drawer } from '@mui/material';

import { useTechnicianProfile } from '@widgets/TechnicianProfileWidget/model/useTechnicianProfile';

import IconClose from '@shared/assets/Icons/icon_close.svg';
import IconPhone from '@shared/assets/Icons/icon_phone_small.svg';
import IconEmail from '@shared/assets/Icons/icon_letter_small.svg';
import IconChat from '@shared/assets/Icons/icon_chat_small.svg';
import IconFlagEn from '@shared/assets/Icons/flag_en.svg';
import IconFlagDe from '@shared/assets/Icons/flag_de.svg';
import IconFlagFr from '@shared/assets/Icons/flag_fr.svg';
import IconFlagPt from '@shared/assets/Icons/flag_pt.svg';
import IconFlagLb from '@shared/assets/Icons/flag_lb.svg';
import { TECHNICIAN_SCHEDULE } from '@widgets/TechnicianProfileWidget/config/constants';

const languages = {
  en: IconFlagEn,
  de: IconFlagDe,
  fr: IconFlagFr,
  lb: IconFlagLb,
  pt: IconFlagPt,
} as Record<string, string>;

interface ITechnicianProfileWidgetProps {
  profileId: string
  isVisible: boolean
  setVisible: (boolean) => void
}

const TechnicianProfileWidget: React.FC<ITechnicianProfileWidgetProps> = ({ profileId, isVisible, setVisible }) => {
  const { t } = useTranslation();
  const { data } = useTechnicianProfile(profileId);
  return (
    <Drawer
      open={isVisible}
      onClose={() => {
        setVisible(false);
      }}
      anchor="right"
    >
      <button
        className="button button--small button--drawer"
        style={{ position: 'absolute', zIndex: 15 }}
        onClick={() => {
          setVisible(false);
        }}>
        <img src={IconClose} width="32" height="32" alt="close"/>
      </button>
      <div className="drawer__inner">
        <h2>{t('technicianProfile.title')}</h2>
        <div className="drawer__block drawer__block--info">
          <div className="drawer__avatar">
            {data?.image ? (<img src={data.image} alt="profile_image"/>) : data?.first_name[0] + data?.last_name[0]}
          </div>
          <h2>{data?.first_name}{' '}{data?.last_name}</h2>
        </div>

        <div className="drawer__block drawer__block--communication">
          <h2>{t('technicianProfile.contact')}</h2>
          <dl>
            <dt>
              <img src={IconPhone} width="20" height="20" alt="phone"/>
              {t('technicianProfile.phone')}
            </dt>
            <dd>
              <a href="tel:+352123456789" className="tel" style={{ color: '#6D438F' }}>{data?.phone}</a>
            </dd>
          </dl>
          <dl>
            <dt>
              <img src={IconEmail} width="20" height="20" alt="email"/>
              {t('technicianProfile.email')}
            </dt>
            <dd>
              <a href="mailto:email@mail.example" style={{ color: '#6D438F' }}>{data?.email}</a>
            </dd>
          </dl>
          {/* <dl> */}
          {/*  <dt> */}
          {/*    <img src={IconChat} width="20" height="20" alt="chat"/> */}
          {/*      Chat */}
          {/*  </dt> */}
          {/*  <dd> */}
          {/*    <a href="" style={{ color: '#6D438F' }}>Send message</a> */}
          {/*  </dd> */}
          {/* </dl> */}
        </div>

        <div className="drawer__block drawer__block--languages">
          <h2>{t('technicianProfile.languages')}</h2>
          <div className="drawer__block--languages-inner">
            {data?.preferred_languages?.map(lang =>
              <img key={lang} src={languages[lang]}
                width="32" height="24" alt=""/>,
            )}
          </div>
        </div>

        {/*<div className="drawer__block drawer__block--statistic">*/}
        {/*  <h2>{t('technicianProfile.stats')}</h2>*/}
        {/*  <dl>*/}
        {/*    <dt>{t('technicianProfile.active')}</dt>*/}
        {/*    <dd>{data?.active_tasks_quantity}</dd>*/}
        {/*  </dl>*/}
        {/*  <dl>*/}
        {/*    <dt>{t('technicianProfile.completed')}</dt>*/}
        {/*    <dd>{data?.completed_tasks_quantity}</dd>*/}
        {/*  </dl>*/}
        {/*</div>*/}

        <div className="drawer__block drawer__block--schedule">
          <h2>{t('technicianProfile.schedule')}</h2>
          {TECHNICIAN_SCHEDULE.map((day, index) => {
            if (data?.schedule) {
              for (let i = 0; i < data?.schedule?.length; i++) {
                if (data?.schedule[i].weekday === day.weekday) {
                  return (
                    <dl key={index + day.weekday}>
                      <dt>{t(day.weekday_title)}</dt>
                      <dd>{data?.schedule[i].work_from + ' - ' + data?.schedule[i].work_to}</dd>
                    </dl>
                  );
                }
              }
            }
            return (
              <dl key={index + day.weekday}>
                <dt>{t(day.weekday_title)}</dt>
                <dd>{t('technicianProfile.day-off')}</dd>
              </dl>
            );
          })}
        </div>
      </div>
    </Drawer>
  );
};

export default TechnicianProfileWidget;
