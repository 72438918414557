import React from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames/bind';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface IActionButtonsProps {
  onClose: (arg: boolean) => void
  isValid: boolean
}

const ActionButtons: React.FC<IActionButtonsProps> = ({ onClose, isValid }) => {
  const { t } = useTranslation();
  return (
    <div className={cx('action-buttons')}>
      <button
        type="button"
        className={cx('button', 'button--border')}
        onClick={() => {
          onClose(true);
        }}
      >
        {t('createServiceRequest.cancel')}
      </button>
      <button className={cx('button', 'button--default')} disabled={!isValid}>
        {t('createServiceRequest.create')}
      </button>
    </div>
  );
};

export default ActionButtons;
