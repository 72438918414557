import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { type AxiosPromise, type AxiosResponse } from 'axios';
import { apiClientAWS } from '@shared/api/base';

interface IUploadMediaProcess {
  'Content-Type': string
  'key': string
  'policy': string
  'x-amz-algorithm': string
  'x-amz-credential': string
  'x-amz-date': string
  'x-amz-signature': string
  file: File
  url: string
}

export const useUploadVideoCoverProcess = (): UseMutationResult<
  unknown,
  unknown,
  IUploadMediaProcess,
  unknown
  > => {
  const uploadVideoCoverProcess = async (payload: IUploadMediaProcess): AxiosPromise<AxiosResponse> => {
    return await apiClientAWS.post(
      payload.url,
      payload,
    );
  };

  const mutationOptions: {
    mutationFn: (payload: IUploadMediaProcess) => AxiosPromise<AxiosResponse>
  } = {
    mutationFn: uploadVideoCoverProcess,
  };

  return useMutation(mutationOptions);
};
