// @ts-nocheck
import React, { useRef, useState } from 'react';
import './styles.scss';
import Close from '@shared/assets/Icons/icon_gallery_close.svg';
import ReactPlayer from 'react-player';
import IconPlay from '@shared/assets/Icons/icon_play.svg';
import IconPlayPlayer from '@shared/assets/Icons/icon_play_player.svg';
import IconPausePlayer from '@shared/assets/Icons/icon_pause_player.svg';
import IconVolumePlayer from '@shared/assets/Icons/icon_volume_player.svg';
import closeIcon from '@shared/assets/Icons/icon_close.svg';
import { type IVideo } from '@widgets/CreateServiceRequestWidget/config/interfaces';

interface IVideoPlayerProps {
    videos: IVideo[] | undefined
    createServiceRequest?: boolean
    coverSrc?: string
    setVideoId?: (string) => void
    handleRemoveVideo?: (string) => void
}

const VideoPlayer: React.FC = ({ videos, createServiceRequest, setVideoId, handleRemoveVideo, coverSrc }) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [isShowPlayer, setShowPlayer] = useState(false);
  const playerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);

  const handlePlayPause = (): void => {
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = (e): void => {
    setCurrentTime(Math.round(e.playedSeconds));
    setDuration(Math.round(e.loadedSeconds));
  };

  const handleSeekTo = (time): void => {
    playerRef?.current?.seekTo(time);
  };

  const handleVolumeChange = (value: string): void => {
    setVolume(parseFloat(value));
  };

  const toggleOverlay = (): void => {
    setShowOverlay(!showOverlay);
  };

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    if (e.currentTarget === e.target) {
      setShowOverlay(!showOverlay);
    }
  };

  return (
    <>
      {createServiceRequest
        ? videos.map((video, index) => (
          <div key={index} className="files-set__item">
            <img src={coverSrc} alt="cover" onClick={toggleOverlay}/>
            <button
              className="button button--small button--delete"
              onClick={(e) => {
                e.preventDefault();
                setVideoId(video.name);
                handleRemoveVideo(video.name);
              }}>
              <img src={closeIcon} width="20" height="20" alt="delete"/>
            </button>
          </div>
        ))
        : videos?.map((video: { cover: { url: string } }, index: number) => {
          return (
            <div className="request__videos__item" key={index} onClick={toggleOverlay}>
              <img src={video.cover.url} width="100%" height="100%" alt="cover"/>
              <button className="button button--play">
                <img src={IconPlay}
                  width="32"
                  height="32" alt="Play"
                />
              </button>
            </div>
          );
        })
      }

      {showOverlay && !!videos?.length && (
        <div className="overlay" onClick={handleOverlayClick}>
          <div className="video-player">
            <ReactPlayer
              className="video-player-view"
              ref={playerRef}
              url={createServiceRequest ? videos[0].href : videos[0].url}
              width="100%"
              height="80vh"
              playing={isPlaying}
              controls={false}
              volume={volume}
              onProgress={handleTimeUpdate}
              onReady={() => { setShowPlayer(true); }}
              onEnded={() => {
                setIsPlaying(false);
                setCurrentTime(0);
                setDuration(0);
              }}
              onError={(error => { console.log(error); })}
            />
            {isShowPlayer && (<div className="video-controls">
              <div className="video-controls__seekbar">
                <input
                  type="range"
                  min={0}
                  max={duration}
                  value={currentTime}
                  onChange={e => { handleSeekTo(Math.round(parseFloat(e.target.value))); }}
                  className="time-slider"
                />
              </div>
              <div className="video-controls__control">
                {isPlaying
                  ? <img src={IconPausePlayer}
                    width="20"
                    height="20" alt="Play"
                    onClick={handlePlayPause}
                  />
                  : <img src={IconPlayPlayer}
                    width="20"
                    height="20" alt="Play"
                    onClick={handlePlayPause}
                  />}
                <img src={IconVolumePlayer}
                  className="volume-icon"
                  width="20"
                  height="20" alt="Volume"
                />
                <input
                  type="range"
                  min={0}
                  max={1} // Volume ranges from 0 to 1
                  step={0.1}
                  value={volume}
                  onChange={(e) => { handleVolumeChange(e.target.value); }}
                  className="volume-slider"
                />
              </div>
            </div>)}
          </div>
          <img className="overlay__close-player" src={Close} onClick={toggleOverlay} alt="close" />
        </div>
      )}
    </>
  );
};

export default VideoPlayer;
