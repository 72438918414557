import React from 'react';
import { ErrorBoundary as ReactErrorBoundary, type FallbackProps } from 'react-error-boundary';
import classNames from 'classnames/bind';

import atysSad from '../../assets/Images/atys_sad.svg';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const ErrorFallback: React.FC<FallbackProps> = () => (
  <div className={cx('container')}>
    <div className={cx('container__message')}>
      <div className={cx('container__message__img')}>
        <img src={atysSad} alt="Atys sad"/>
      </div>
      <div className={cx('container__message__text')}>
        Something went wrong
      </div>
    </div>
  </div>
);

interface ErrorBoundaryProps {
  children: React.ReactNode
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => (
  <ReactErrorBoundary
    FallbackComponent={ErrorFallback}
    onError={(error) => {
      // eslint-disable-next-line no-console
      console.error('ErrorBoundary caught an error', error);
    }}
  >
    {children}
  </ReactErrorBoundary>
);

export default ErrorBoundary;
