import { createTheme } from '@mui/material';

import { components } from './overrides';
import { palette } from './palette';
import { typography } from './typography';

export const Theme = createTheme(
  {
    typography,
    palette,
    components,
  },
);
