export const HeaderNavItems = [
  {
    title: 'header.dashboard',
    path: '/',
  },
  {
    title: 'header.clients',
    path: '/clients',
  },
  {
    title: 'header.team',
    path: '/team',
  },
];
