export const MAX_LIMIT_TIME_PERIOD = 3;

export const UNITS = [
  {
    id: 1,
    label: 'units.pieces',
    shortLabel: 'units.short-pieces',
    value: 'PIECES',
  },
  {
    id: 2,
    label: 'units.hours',
    shortLabel: 'units.short-hours',
    value: 'HOURS',
  },
  {
    id: 3,
    label: 'units.meters',
    shortLabel: 'units.short-meters',
    value: 'METERS',
  },
  {
    id: 4,
    label: 'units.cubic-meters',
    shortLabel: 'units.short-cubic-meters',
    value: 'CUBIC_METERS',
  },
  {
    id: 5,
    label: 'units.square-meters',
    shortLabel: 'units.short-square-meters',
    value: 'SQUARE_METERS',
  },
  {
    id: 6,
    label: 'units.liters',
    shortLabel: 'units.short-liters',
    value: 'LITERS',
  },
  {
    id: 7,
    label: 'units.kilograms',
    shortLabel: 'units.short-kilograms',
    value: 'KILOGRAMS',
  },
  {
    id: 8,
    label: 'units.tons',
    shortLabel: 'units.short-tons',
    value: 'TONS',
  },
];
