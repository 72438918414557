import { alpha, getContrastRatio } from '@mui/material';

import type { PaletteOptions } from '@mui/material/styles/createPalette';

export const palette: PaletteOptions = {
  common: {
    white: '#FFF',
    black: '#000',
  },
  primary: {
    main: '#6d438f',
    light: alpha('#6d438f', 0.5),
    dark: '#322959',
    contrastText: getContrastRatio('#273B48', '#FFF') > 4.5 ? '#FFF' : '#111',
  },
  info: {
    main: '#8083A3',
  },
};
