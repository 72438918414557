import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { HeaderWidget } from '@widgets/index';
import { ROUTES } from '@shared/config/routes/constants';
import { localStorageGetItem } from '@shared/lib/utils/localStorage';
import { STORAGE_KEYS } from '@pages/AuthPage/config/constants';
import { ErrorBoundary } from '@shared/HOC';

const MainLayout: React.FC = () => {
  const navigate = useNavigate();
  const isAuth = !!localStorageGetItem(STORAGE_KEYS.TOKEN);

  useEffect(() => {
    if (!isAuth) {
      navigate(ROUTES.AUTH, { replace: true });
    }
  }, [isAuth, navigate]);

  return isAuth
    ? (
      <>
        <HeaderWidget />
        <ErrorBoundary>
          <div className="container">
            <Outlet />
          </div>
        </ErrorBoundary>
      </>)
    : null;
};

export default MainLayout;
