export const localStorageGetItem = (key: string): string | null => {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  }
};

export const localStorageSetItem = (key: string, value: string | number): void => {
  try {
    if (typeof value === 'string') {
      localStorage.setItem(key, value);
    }
    if (typeof value === 'number') {
      localStorage.setItem(key, value.toString());
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

export const localStorageRemoveItem = (key: string): void => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};
