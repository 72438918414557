import React from 'react';
import ReactPaginate from 'react-paginate';
import classNames from 'classnames/bind';
import { Box, FormControl, MenuItem, Select } from '@mui/material';

import { type IClientsList } from '../../config/interfaces';

import { PAGINATION_ITEMS_LIST } from '../../config/constants';

import styles from './styles.module.scss';

const cx = classNames.bind(styles) as {
  (name: string): string
  (names: Record<string, boolean | undefined>): string
};

interface IPaginationProps {
  tableData: IClientsList
  setPage: (size: number) => void
  pageSize: number
  setPageSize: (pageSize: number) => void
}

interface IReactPaginatePageClickEvent {
  selected: number
}

const Pagination: React.FC<IPaginationProps> = ({ tableData, setPage, setPageSize, pageSize }) => {
  const handlePageClick = (event: IReactPaginatePageClickEvent): void => {
    setPage(event.selected + 1);
  };

  const pageCount = Math.ceil(tableData?.count / pageSize);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <ReactPaginate
        breakLabel="..."
        nextLabel=""
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={pageCount === 1 ? 0 : pageCount}
        previousLabel=""
        renderOnZeroPageCount={() => (
          <Box
            className={cx('pagination')}
          />
        )}
        containerClassName={cx('pagination-container')}
        previousClassName={cx('pagination-previous')}
        nextClassName={cx('pagination-next')}
        className={cx('pagination')}
        pageClassName={cx('pagination-page')}
        pageLinkClassName={cx('pagination-link')}
        breakClassName={cx('pagination-page')}
        activeClassName={cx('pagination-active')}
      />

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <FormControl
          sx={{
            minWidth: 48,
            display: 'flex',
            height: '30px',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'var(--gray-middle)',
                borderRadius: '8px',
                borderWidth: '1px !important',
                transition: '0.3s',
              },
              '&:hover': {
                '& fieldset': {
                  borderColor: 'var(--accent-hover)',
                },
              },
            },
          }}
        >
          <Select
            labelId="client-status-label"
            id="client-status-label-select"
            label=""
            IconComponent={() => null}
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              setPage(1);
            }}
            className={cx('pagination-select-wrapper')}
          >
            {PAGINATION_ITEMS_LIST.map((item) => (
              <MenuItem key={item.id} value={item.value} className={cx('pagination-item')}>{item.label}</MenuItem >
            ))}
          </Select>
        </FormControl>
        <Box
          sx={{
            color: 'var(--gray-light-text)',
            fontSize: '1.4rem',
          }}
        >
          per page
        </Box>
      </Box>
    </Box>
  );
};

export default Pagination;
