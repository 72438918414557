// @ts-nocheck
/* TODO: Проверка отключена из-за google-maps-react.
    Ошибка в импортах и <Map> не может иметь {children}, функционал работает */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AdvancedMarker,
  Map,
  useMap,
} from '@vis.gl/react-google-maps';
import iconMarker from '@shared/assets/Icons/icon_marker.svg';
import './styles.scss';

import { useServiceProviderProfile } from '@widgets/HeaderWidget/model/useServiceProviderProfile';

import { LuxembourgLocation } from '@shared/config/map/constants';

const ClientsMap: React.FC = ({ serviceRequestData }) => {
  const { t } = useTranslation();
  const map = useMap();
  const { data } = useServiceProviderProfile();
  const SPLocation =
    {
      lat: data?.address?.location?.latitude ?? 0,
      lng: data?.address?.location?.longitude ?? 0,
    };
  const CustomerLocation =
    {
      lat: serviceRequestData?.location?.latitude ?? 0,
      lng: serviceRequestData?.location?.longitude ?? 0,
    };

  useEffect(() => {
    if (!map) return;
    const bounds = new google.maps.LatLngBounds();
    bounds.extend(SPLocation);
    bounds.extend(CustomerLocation);
    map.fitBounds(bounds);
  }, [CustomerLocation, SPLocation, map]);

  return (
    <Map
      mapId={'5345sdg'}
      style={{ width: '333px', height: '192px', borderRadius: '8px' }}
      defaultZoom={15}
      defaultCenter={LuxembourgLocation}
      gestureHandling={'greedy'}
      disableDefaultUI
    >
      {SPLocation &&
          <AdvancedMarker
            position={SPLocation}
          >
            <div className="custom-marker__container">
              <img className="custom-marker__icon" src={iconMarker} alt="marker"/>
              <img className="custom-marker__avatar" src={data?.image} alt="marker"/>
              <div className="custom-marker__info">{t('detailServiceRequest.sp-marker-label')}</div>
            </div>
          </AdvancedMarker>
      }
      {CustomerLocation &&
          <AdvancedMarker
            position={CustomerLocation}
            zIndex={5}
          >
            <div className="custom-marker__container">
              <img className="custom-marker__icon" src={iconMarker} alt="marker"/>
              <div className="custom-marker__person">
                {serviceRequestData?.customer?.first_name[0]}{serviceRequestData?.customer?.last_name[0]}
              </div>
              <span className="custom-marker__info-client">
                {serviceRequestData?.customer?.first_name}{','}&nbsp;{serviceRequestData?.distance.toFixed(1)}&nbsp;{'km'}
              </span>
            </div>
          </AdvancedMarker>
      }
    </Map>
  );
};

export default ClientsMap;
