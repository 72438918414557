import type { Components } from '@mui/material/styles/components';
import type { Theme } from '@mui/material/styles/createTheme';

export const MuiButton: Components<Theme>['MuiButton'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontFamily: 'Public Sans',
      fontSize: '1rem',
      height: '48px',
      fontWeight: 600,
      borderRadius: '8px',
      boxShadow: 'none',
      padding: theme.spacing(1.75, 2.5, 1.5, 2.5),

      '&.MuiButton-outlined': {
        '&.Mui-disabled': {
          borderColor: '#F1F2F4',
          color: '#A0AEC0',
          backgroundColor: '#F1F2F4',
        },
      },
    }),
  },
  defaultProps: {
    variant: 'contained',
  },
};
