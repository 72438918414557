import { type IModalWindowsTypes, type IServiceCategory } from './interfaces';

export const STORAGE_KEYS = {
  TOKEN: 'token',
};

export enum MEDIA_TYPES {
  IMAGE,
  VIDEO,
}

export const SERVICE_CATEGORY_LIST: IServiceCategory[] = [
  {
    id: '1',
    value: 'MAINTENANCE',
    label: 'Maintenance',
  },
  {
    id: '2',
    value: 'REPAIR',
    label: 'Repair',
  },
  {
    id: '3',
    value: 'PROJECT',
    label: 'Project',
  },
  {
    id: '4',
    value: 'OTHER',
    label: 'Other',
  },
];

export const MODAL_WINDOWS_TYPES: IModalWindowsTypes = {
  CONFIRM: {
    title: 'Quick reminder before you send',
    description: 'Before you send your request, please take a moment to review the details you’ve entered. ' +
      'A brief review now can save time later and helps us serve you better.',
    firstBtnText: 'Review',
    secondBtnText: 'Send',
  },
  DONE: {
    title: 'Service request created',
    subtitle: 'Thank you',
    description: 'The service request has been successfully sent for processing. ' +
      'An email has been sent to the client',
    firstBtnText: 'Request',
    secondBtnText: 'Dashboard',
  },
  CANCEL: {
    title: 'Cancel the request',
    subtitle: 'Attention!',
    description: 'Would you like to cancel your service request? ' +
      'All data will be deleted',
    firstBtnText: 'No',
    secondBtnText: 'Yes',
  },
};
