// @ts-nocheck
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import classNames from 'classnames';
import IconTabBoard from '@shared/assets/Icons/icon_tab_board.svg';
import IconTabList from '@shared/assets/Icons/icon_tab_list.svg';
import IconReset from '@shared/assets/Icons/icon_reset.svg';
import { useServiceRequestsList } from '@pages/DashboardPage/model/useServiceRequestList';
import DashboardGridViewWidget from '@widgets/DashboardGridViewWidget';
import DashboardListViewWidget from '@widgets/DashboardListViewWidget';
import { Button, FormControl, Icon, MenuItem, Select } from '@mui/material';
import { CreateServiceRequestWidget } from '@widgets/index';

import { REQUEST_STATUS_LIST, TYPE_OF_WORK_LIST } from './config/constants';

const selectSxStyles = {
  minWidth: 142,
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'var(--gray-middle)',
      borderRadius: '8px',
      borderWidth: '1px !important',
      transition: '0.3s',
    },
    '&:hover': {
      '& fieldset': {
        borderColor: 'var(--accent-hover)',
      },
    },
  },
};

const DashboardPage: React.FC = () => {
  const { t } = useTranslation();
  const ResetIcon = (
    <Icon>
      <img src={IconReset} alt="Reset filters"/>
    </Icon>
  );
  const [isBoardView, setBoardView] = useState<boolean>(true);
  const [statusFilter, setStatusFilter] = useState<string>('');
  const [typeWorkFilter, setTypeWorkFilter] = useState<string>('');
  const { data } = useServiceRequestsList(statusFilter, typeWorkFilter);

  return (
    <main>
      <div className="container">
        <div className="filters">
          <div className="tabs">
            <button
              className={classNames('tab tab--board', {
                active: isBoardView,
              })}
              onClick={() => { setBoardView(true); }}>
              <img src={IconTabBoard} width="20" height="20" alt=""/>
              {t('dashboard.board-view')}
            </button>
            <button
              className={classNames('tab tab--list', {
                active: !isBoardView,
              })}
              onClick={() => { setBoardView(false); }}>
              <img src={IconTabList} width="20" height="20" alt=""/>
              {t('dashboard.list-view')}
            </button>
          </div>

          {/* <FormControl */}
          {/*  sx={selectSxStyles} */}
          {/* > */}
          {/*  <Select */}
          {/*    className="select-wrapper" */}
          {/*    IconComponent={() => null} */}
          {/*    labelId="client-status-label" */}
          {/*    id="client-status-label-select" */}
          {/*    label="" */}
          {/*    value="empty" */}
          {/*  > */}
          {/*    <MenuItem value="empty" className="select-item">Client Status</MenuItem > */}
          {/*    <MenuItem value="VIP" className="select-item">VIP</MenuItem > */}
          {/*    <MenuItem value="New" className="select-item">New</MenuItem > */}
          {/*    <MenuItem value="Regular" className="select-item">Regular</MenuItem > */}
          {/*  </Select> */}
          {/* </FormControl> */}

          <FormControl
            sx={selectSxStyles}
          >
            <Select
              className="select-wrapper"
              IconComponent={() => null}
              labelId="client-status-label"
              id="client-status-label-select"
              label=""
              value={typeWorkFilter || 'empty'}
            >
              {TYPE_OF_WORK_LIST.map((type) => {
                if (type.id === TYPE_OF_WORK_LIST[0].id) {
                  return (
                    <MenuItem
                      key={type.id}
                      value={type.value}
                      className="select-item"
                    >
                      {t(type.label)}
                    </MenuItem >
                  );
                }
                return (
                  <MenuItem
                    key={type.id}
                    value={type.value}
                    className="select-item"
                    onClick={(): void => { setTypeWorkFilter(type.value); }}
                  >
                    {t(type.label)}
                  </MenuItem >
                );
              })}
            </Select>
          </FormControl>

          {!isBoardView && (
            <FormControl
              sx={{ ...selectSxStyles, minWidth: 152 }}
            >
              <Select
                className="select-wrapper"
                IconComponent={() => null}
                labelId="client-status-label"
                id="client-status-label-select"
                label=""
                value={statusFilter || 'empty'}
              >
                {REQUEST_STATUS_LIST.map((status) => {
                  if (status.id === REQUEST_STATUS_LIST[0].id) {
                    return (
                      <MenuItem
                        key={status.id}
                        value={status.value}
                        className="select-item"
                      >
                        {t(status.label)}
                      </MenuItem >
                    );
                  }
                  return (
                    <MenuItem
                      key={status.id}
                      value={status.value}
                      className="select-item"
                      onClick={(): void => { setStatusFilter(status.value); }}
                    >
                      {t(status.label)}
                    </MenuItem >
                  );
                })}
              </Select>
            </FormControl>
          )}

          <Button
            variant="text"
            startIcon={ResetIcon}
            className="button--text-small"
            disableRipple
            onClick={(): void => {
              setStatusFilter('');
              setTypeWorkFilter('');
            }}
          >
            {t('dashboard.reset')}
          </Button>

          <CreateServiceRequestWidget />

        </div>
        {
          isBoardView
            ? <DashboardGridViewWidget serviceRequestList={data}/>
            : <DashboardListViewWidget serviceRequestList={data} />
        }
      </div>
    </main>
  );
};

export default DashboardPage;
